import {NgModule} from "@angular/core";
import {HttpClientModule} from "@angular/common/http";
import {CommonModule} from "@angular/common";
import {ConfigAssetsApiService} from "./config-assets-api.service";
import {ConfigAssetsEditorComponent} from "./config-assets-editor/config-assets-editor.component";
import {MatCardModule} from "@angular/material/card";
import {NgJsonEditorModule} from "ang-jsoneditor";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    ConfigAssetsEditorComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MatCardModule,
    NgJsonEditorModule,
    MatButtonModule,
    MatInputModule,
    FormsModule
  ],
  providers: [
    ConfigAssetsApiService
  ],
  exports: [],
  bootstrap: []
})
export class AssetsModule {
}
