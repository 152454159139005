<mat-card>
  <h5>Application Login</h5>
  <form #loginForm="ngForm" (ngSubmit)="login()">
    <table class="table table-sm table-hover table-striped table-borderless">
      <thead>
      <tr>
        <th>Login</th>
      </thead>
      <tbody>
      <tr class="link-active">
        <td class="text-left table-cell-wide">
          <mat-form-field>
            <input matInput placeholder="Enter Username" type="text" name="user"
                   [(ngModel)]="auth.user"/>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Enter Password" type="password" name="password"
                   [(ngModel)]="auth.password"/>
          </mat-form-field>
          <div class="mat-error" *ngIf="auth.error">{{auth.error}}</div>
        </td>
      </tr>
      <tr class="link-active">
        <td class="text-left table-cell-wide">
          <button mat-stroked-button color="warn" type="submit" (submit)="login()">login</button>
        </td>
      </tr>
      </tbody>
    </table>
  </form>
</mat-card>

