import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from '../../app-routing.module';
import {ContextApiService} from '../contexts/context-api.service';
import {RecordApiService} from "./record-api.service";
import {AcdcApiService} from "../contexts/acdc-api.service";
import {StationApiService} from "../stations/station-api.service";

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    AppRoutingModule
  ],
  declarations: [],
  exports: [],
  providers: [
    RecordApiService,
    ContextApiService,
    AcdcApiService,
    StationApiService
  ]
})
export class RecordApiModule {
}
