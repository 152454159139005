import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {HttpClient} from '@angular/common/http';
import {BaseService} from "../base/base.service";
import {map} from "rxjs/operators";

export class StationStatus {
  id: string;
  stationId: string;
  state: string;
  condition: string;
  description: string;
  meta: any;
  created: Date;
}

export interface ConfigAssetsList {
  project: string;
  assets: string[];
}

@Injectable()
export class ConfigAssetsApiService {

  constructor(private baseSvc: BaseService, private http: HttpClient) {
  }

  public getConfig(configId: string): Observable<any> {
    const url = this.baseSvc.projectUrl(`assets/${configId}`);
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions());

    return req;
  }

  public setConfig(key: string, config: any): Observable<void> {
    const url = this.baseSvc.projectUrl(`assets/${key}`);
    const req: Observable<any> = this.http
      .put(url, JSON.stringify(config), this.baseSvc.defaultOptions());

    return req;
  }

  public listConfigs(): Observable<ConfigAssetsList> {

    const url = this.baseSvc.projectUrl(`assets/list-configs`);
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions())
      .pipe(map((res: any) => res as ConfigAssetsList))
    return req;
  }

}
