import {Component, OnInit} from '@angular/core';
import {ServerConfigApiService} from "../server-config-api.service";
import {AppComponent} from "../../../app.component";
import {PropertyOverride, SpringPropertyRecord} from "../server-config-models";

@Component({
  selector: 'app-server-config',
  templateUrl: './server-config.component.html',
  styleUrls: ['./server-config.component.scss']
})
export class ServerConfigComponent implements OnInit {

  public configNames: string[] = [];
  public selectedConfigName: string = null;
  public configProperties: SpringPropertyRecord[] = null;
  public expandedKey: string = null;

  constructor(
    private serverConfigApi: ServerConfigApiService,
    private appComponent: AppComponent
  ) {
  }

  ngOnInit(): void {
    this.serverConfigApi.getConfigNames().subscribe(configNames => {
        this.configNames = configNames;
      },
      error => {
        this.appComponent.showError("Config summaries load Failure", error);
      }
    );
  }

  onSelectConfigName(selConfigName: string): void {
    this.selectedConfigName = selConfigName;
    this.reloadConfiguration();
  }

  saveProperty(property: SpringPropertyRecord): void {
    if (property.propertyOverride != null) {
      this.serverConfigApi.saveProperty(property.key, property.propertyOverride.pendingValue).subscribe(
        _ => {
          property.propertyOverride.value = property.propertyOverride.pendingValue;
        },
        error => {
          this.appComponent.showError("Server Config Save Property Failure", error);
        }
      );
    }
  }

  reloadConfiguration(): void {
    if (this.selectedConfigName) {
      this.serverConfigApi.getConfig(this.selectedConfigName).subscribe(configProps => {
          // Since there is editing involved, initially set the pendingValue of
          // the propertyOverride to the original value and making the editing of
          // the pendingValue enabled; Also, initially mark the override as enabled
          // for the UI if it is present after loading and mark it as having been
          // persisted previously
          configProps.forEach(prop => {
            if (prop.propertyOverride == null) {
              prop.propertyOverride = new PropertyOverride();
              prop.propertyOverride.value = prop.value;
            }
            prop.propertyOverride.pendingValue = prop.propertyOverride.value;
          });
          this.configProperties = configProps;
        },
        error => {
          this.appComponent.showError("Server Config Properties Failure", error);
        }
      );
    } else {
      this.configProperties = null;
    }
  }

  afterExpandPanel(propKey): void {
    this.expandedKey = propKey;
  }
}
