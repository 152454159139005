import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';
import {AppComponent} from "../../../app.component";
import {MonitoredStationConfig, StationApiService} from "../station-api.service";

@Component({
  selector: 'ae-station-config',
  templateUrl: './station-config.component.html',
  styleUrls: ['./station-config.component.scss']
})
export class StationConfigComponent implements OnInit, OnDestroy {
  configJson: MonitoredStationConfig = null;
  editorOptions;

  @ViewChild('editor', {static: false}) editor: JsonEditorComponent;

  constructor(private stationSvc: StationApiService, private app: AppComponent) {
  }

  ngOnInit(): void {
    const options = new JsonEditorOptions();
    options.mode = 'code';
    options.modes = ['code', 'text'];
    this.editorOptions = options;
    this.loadConfig();
  }

  ngOnDestroy(): void {
  }

  loadConfig(): void {
    this.stationSvc.getConfig().subscribe(config => {
        this.configJson = config;
      },
      error => {
        this.app.showError("Load Station Config Error", error)
      })
  }

  updateConfig(): void {
    const jsonText = JSON.stringify(this.editor.get(), null, 2);
    const config: MonitoredStationConfig = JSON.parse(jsonText);
    this.stationSvc.setConfig(config).subscribe(
      () => {
        console.log('updated', config);
        this.loadConfig();
      },
      error => {
        this.app.showError(`Error updating station config`, error)
      });
  }

  cancel(): void {
    this.loadConfig();
  }

}
