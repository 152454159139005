<div>
  <div *ngIf="selectedRow">
    <div class="app-toolbar">
      <mat-toolbar color="accent">
        <div class="col-1 close-cont" #toolbar>
          <button id="close-details" mat-icon-button (click)="hideDetails()">
            Close
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="col-1 d-flex justify-content-start">
          <h1 class="details-description">{{dataViewDefinition?.name}} Details</h1>
          <mat-spinner *ngIf="busy" diameter="30" [strokeWidth]="5" color="accent"></mat-spinner>
          <mat-icon *ngIf="!busy" size="30">blank</mat-icon>
        </div>
      </mat-toolbar>
    </div>

    <table class="table table-sm table-dark table-hover table-striped">
      <thead>
      <tr>
        <th *ngFor="let header of dataViewDefinition?.tableFields">
          {{header.name}}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td *ngFor="let header of dataViewDefinition?.tableFields">
          {{selectedRow[header.key]}}
        </td>
      </tr>
      </tbody>
    </table>

    <div *ngIf="rowDetails">
      <div *ngFor="let dField of dataViewDefinition?.rowDetails?.detailFields">
        <h2>{{dField.name}}</h2>
        <div *ngIf="rowDetails[dField.key]" class="preview-wrapper">
          <span *ngIf="dField.datatype != 'IMAGE_ID'">{{rowDetails[dField.key]}}</span>
          <img *ngIf="dField.datatype == 'IMAGE_ID'" class="preview preview-sm"
               [src]="computeImageUrl(rowDetails[dField.key]) | previewImage | async"
               referrerpolicy="unsafe-url">
        </div>

      </div>
    </div>
  </div>

  <div *ngIf="!selectedRow">
    <mat-toolbar color="primary">
      <div class="manager-toolbar row" #toolbar>
        <div class="col-2">
          <div>
            <div *ngIf="searchOptions?.rangeSearchFeature?.enabled" class="text-right">
              <mat-hint class="hint-tiny">Time Range:</mat-hint>
              <mat-form-field class="last-n hint-tiny">
                <mat-select [ngModel]="searchOptions.lastNMinutes"
                            (ngModelChange)="searchOptions?.setLastNMinutes($event)">
                  <mat-option value="-1">custom</mat-option>
                  <mat-option value="5">last 5 Minutes</mat-option>
                  <mat-option value="10">last 10 Minutes</mat-option>
                  <mat-option value="30">last 30 Minutes</mat-option>
                  <mat-option value="60">last Hour</mat-option>
                  <mat-option value="120">last 2 Hours</mat-option>
                  <mat-option value="240">last 4 Hours</mat-option>
                  <mat-option value="360">last 6 Hours</mat-option>
                  <mat-option value="1440">last Day</mat-option>
                  <mat-option value="10080">last Week</mat-option>
                  <mat-option value="43200">last Month</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div>
            <div *ngIf="searchOptions?.rangeSearchFeature?.enabled"
                 class="text-right hint-tiny form-field">
              <mat-hint class="hint-tiny">Start Range:</mat-hint>
              <mat-form-field>
                <input class="hint-tiny" matInput type="text" name="refreshRate"
                       [(ngModel)]="searchOptions.startTime"/>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div>
            <div *ngIf="searchOptions?.rangeSearchFeature?.enabled"
                 class="text-right hint-tiny form-field">
              <mat-hint class="hint-tiny">End Range:</mat-hint>
              <mat-form-field>
                <input class="hint-tiny" matInput type="text" name="refreshRate"
                       [(ngModel)]="searchOptions.endTime"/>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-2">
          <div>
            <div *ngIf="searchOptions?.autoRefreshFeature?.enabled" class="text-right hint-tiny">
              <button mat-icon-button (click)="loadData()">
                <mat-icon size="50">refresh</mat-icon>
              </button>
              &nbsp; | &nbsp;
              <a (click)="searchOptions.toggleAutoUpdate()" class="hint-tiny link-active">auto
                update: <span
                  class="ae-emphasized hint-tiny">{{searchOptions.isAutoUpdateOn
                  ? searchOptions.autoUpdateSeconds
                  + " (s)"
                  : "off"}}</span> </a>
            </div>
            <div *ngIf="!searchOptions?.autoRefreshFeature?.enabled" class="text-right hint-tiny">
              <button mat-icon-button (click)="loadData()">
                Refresh:
                <mat-icon size="50">refresh</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-toolbar>

    <div class="data-view">
      <div class="col-4">
        <h1>{{dataViewDefinition?.name}}</h1>
      </div>

      <div class="col-4">
        <table *ngIf="dataViewDefinition?.summaryFields" class="table table-sm table-bordered">
          <tr *ngFor="let s of dataViewDefinition?.summaryFields">
            <td>{{s.name}}</td>
            <td>{{dataView?.summary[s.key]}}</td>
          </tr>
        </table>
      </div>

      <div class="app-toolbar">
        <mat-toolbar color="accent">
          <div class="col-1 d-flex justify-content-start">
            Results
            <mat-spinner *ngIf="busy" diameter="30" [strokeWidth]="5"
                         color="accent"></mat-spinner>
            <mat-icon *ngIf="!busy" size="30">blank</mat-icon>
          </div>
          <div *ngIf="extractCsvFeature?.enabled" class="col-2">
            <button mat-icon-button (click)="saveCsv()">
              Export All
              <mat-icon size="50">save_alt</mat-icon>
            </button>
          </div>
        </mat-toolbar>
      </div>

      <div *ngIf="dataViewDefinition?.getShowTableFeature()">
        <table class="table table-sm table-dark table-hover table-striped">
          <thead>
          <tr>
            <th *ngFor="let header of dataViewDefinition?.tableFields">
              {{header.name}}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of dataView?.rows; index as i"
              [ngClass]="{'link-active': dataViewDefinition?.rowDetails}"
              (click)="selectRow(i)"
          >
            <td *ngFor="let header of dataViewDefinition?.tableFields">
              {{row[header.key]}}
            </td>
          </tr>
          </tbody>
        </table>

      </div>

    </div>
  </div>

</div>
