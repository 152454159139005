<h2 mat-dialog-title>{{title}}</h2>

<mat-dialog-content>

  {{message}}

  <pre>
    {{details}}
  </pre>

</mat-dialog-content>

<mat-dialog-actions>
  <button class="mat-raised-button" (click)="close()">Close</button>
</mat-dialog-actions>
