import {Component, Inject, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {Subscription, timer} from 'rxjs';
import {ContextApiService} from "../../contexts/context-api.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {DataViewApiService} from "../data-view-api.service";
import {
  AutoRefreshFeature,
  DataViewDefinition,
  DataViewResult, DataViewRow,
  DownloadCsvFeature,
  RangeSearchFeature
} from "../data-view-models";
import {formatDate} from "@angular/common";
import {RecordApiService} from "../record-api.service";
import {Observable} from "rxjs/internal/Observable";
import {map} from "rxjs/operators";
import {AppComponent} from "../../../app.component";
import {UserService} from "../../user/user.service";

@Component({
  selector: 'data-viewer',
  templateUrl: './data-viewer.component.html',
  styleUrls: ['./data-viewer.component.scss']
})
export class DataViewerComponent implements OnInit, OnDestroy {

  public searchOptions: SearchOptions = new SearchOptions(
    'data-viewer',
    () => this.loadData()
  );

  private contextId: string;
  private navUrl: string;
  private contextIdSub: Subscription;
  private routeSub: Subscription;
  private isAdminSub: Subscription;
  public dataViewDefinition: DataViewDefinition;
  public dataView: DataViewResult;
  public extractCsvFeature: DownloadCsvFeature | null = null;
  public busy = false;
  public selectedRow: DataViewRow | null = null;
  public rowDetails: object | null = null;
  public isAdmin = false;

  constructor(
    private contextApi: ContextApiService,
    private dataViewApi: DataViewApiService,
    private recordApi: RecordApiService,
    private userService: UserService,
    private appComponent: AppComponent,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.routeSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects !== this.navUrl) {
          this.navUrl = event.urlAfterRedirects;
          this.onDefinitionChange();
        }
      }
    });
    this.contextIdSub = this.contextApi.getCurrentContextId().subscribe(
      ctxId => {
        if (ctxId) {
          this.contextId = ctxId;
          this.loadData();
        }
      }
    );
    this.isAdminSub = this.userService.isAdmin().subscribe(isAdmin => this.isAdmin = isAdmin);
  }

  ngOnInit(): void {
    this.onDefinitionChange();
  }

  ngOnDestroy(): void {
    this.searchOptions.stopAutoUpdate();
    if (this.contextIdSub != null) {
      this.contextIdSub.unsubscribe();
      this.contextIdSub = null;
    }
    if (this.routeSub != null) {
      this.routeSub.unsubscribe();
      this.routeSub = null;
    }
    if (this.isAdminSub != null) {
      this.isAdminSub.unsubscribe();
      this.isAdminSub = null;
    }
  }

  public onDefinitionChange(): void {
    this.getCurrentDefinition().subscribe(
      definition => {
        this.selectedRow = null;
        this.dataViewDefinition = definition;
        if (this.dataViewDefinition) {
          this.extractCsvFeature = this.dataViewDefinition.getDownloadCsvFeature();
          this.searchOptions.changeView(
            definition.name,
            this.dataViewDefinition.getAutoRefreshFeature(),
            this.dataViewDefinition.getRangeSearchFeature()
          );
          this.loadData();
        } else {
          this.searchOptions.stopAutoUpdate();
        }
      },
      error => {
        this.appComponent.showError(`Cannot getting current definition`, error);
      }
    );
  }

  onLastNChange(newLastNMinutes?: string): void {
    this.searchOptions.setLastNMinutes(newLastNMinutes);
    this.loadData();
  }

  saveCsv(): void {
    if (this.dataViewDefinition && this.contextId) {
      const definitionKey = this.dataViewDefinition.key;
      this.busy = true;
      try {
        this.dataViewApi.extractCsv(
          this.contextId, definitionKey, this.searchOptions.startTime, this.searchOptions.endTime
        ).subscribe(
          csvText => {
            const anchor = document.createElement('a');
            const csvBlob = new Blob([csvText], {type: 'text/csv'});
            const url = window.URL.createObjectURL(csvBlob);
            anchor.href = url;
            anchor.download = `${definitionKey}_${formatDate(new Date(), 'yyyyMMdd_HHmmss', this.locale)}.csv`;

            anchor.click();
            window.URL.revokeObjectURL(url);
            anchor.remove();
            this.busy = false;
          },
          error => {
            this.appComponent.showError(`Error getting CSV`, error);
            this.busy = false;
          }
        );
      } catch (err) {
        this.appComponent.showError(`Error getting CSV`, err);
        this.busy = false;
      }
    }
  }

  public loadData(): void {
    if (this.dataViewDefinition && this.contextId) {
      const definitionKey = this.dataViewDefinition.key;
      this.busy = true;
      // Update start and end times based on the last N minutes of the current time
      this.searchOptions.recomputeTimeRange();
      try {
        this.dataViewApi.getDataView(
          this.contextId, definitionKey, this.searchOptions.startTime, this.searchOptions.endTime
        ).subscribe(
          dataView => {
            this.dataView = dataView;
            this.busy = false;
          },
          error => {
            this.busy = false;
            this.appComponent.showError(
              `Error Loading data definition: ${definitionKey}`,
              error,
              true
            );
          }
        );
      } catch (err) {
        this.busy = false;
        this.appComponent.showError(
          `Error Loading data definition: ${definitionKey}`,
          err,
          true
        );
      }
    }
  }

  public selectRow(index: number): void {
    if (this.dataViewDefinition?.rowDetails && this.contextId) {
      this.selectedRow = this.dataView.rows[index];
      const detailQuery = this.computeSelectedQueryDetail();
      this.dataViewApi.getRowDetails(
        this.contextId, this.dataViewDefinition.key, detailQuery
      ).subscribe(
        result => {
          this.rowDetails = result;
        },
        error => {
          this.appComponent.showError(`Error getting details`, error);
        }
      );
    }
  }

  public hideDetails(): void {
    this.selectedRow = null;
    this.rowDetails = null;
    this.loadData();
  }

  public computeImageUrl(imageId: string): string {
    return this.recordApi.computePreviewUrl(imageId);
  }

  private getCurrentDefinition(): Observable<DataViewDefinition> {
    const definitionKey = this.route.snapshot.paramMap.get('definitionKey');
    // if the definitionKey is 'default', pick the first definition
    if (definitionKey === 'default') {
      return this.dataViewApi.getDefaultDefinition();
    } else {
      return this.dataViewApi.getDefinitions().pipe(
        map(definitions => definitions.find((d) => d.key === definitionKey))
      );
    }
  }

  private computeSelectedQueryDetail(): object {
    const detailQueryKeys = this.dataViewDefinition.rowDetails.queryFields.map(field => field.key);
    const detailQuery = {};
    detailQueryKeys.forEach(key => {
      if (this.selectedRow[key] !== undefined) {
        detailQuery[key] = this.selectedRow[key];
      }
    });
    return detailQuery;
  }

  formatUtc(timestamp: string): string {
    if (timestamp) {
      return timestamp.split('.')[0] + 'Z';
    }
  }
}


class SearchOptions {
  pageName: string;
  viewName = "default";
  lastNMinutes: string;
  startTime: string = null;
  endTime: string = null;
  isAutoUpdateOn = false;
  autoUpdateSeconds = 10;
  autoUpdateSubscription: Subscription = null;
  reloadCallback: () => void = null;
  autoRefreshFeature: AutoRefreshFeature | null = null;
  rangeSearchFeature: RangeSearchFeature | null = null;

  constructor(pageName: string, reloadCallback: () => void) {
    this.pageName = pageName;
    this.reloadCallback = reloadCallback;
  }


  changeView(newViewName: string, autoRefreshFeature: AutoRefreshFeature, rangeSearchFeature: RangeSearchFeature): void {
    this.stopAutoUpdate();
    this.autoRefreshFeature = autoRefreshFeature;
    this.rangeSearchFeature = rangeSearchFeature;
    this.viewName = newViewName;
    this.loadFromLocalStorage();
    if (this.isAutoUpdateOn) {
      this.autoUpdateSeconds = autoRefreshFeature.intervalSec;
      this.startAutoUpdate();
    }
  }

  public toggleAutoUpdate(): void {
    const newAutoUpdateOn = !this.isAutoUpdateOn;
    if (newAutoUpdateOn) {
      this.startAutoUpdate();
    } else {
      this.stopAutoUpdate();
    }
  }

  startAutoUpdate(): void {
    // Ensure that any previous subscription is unsubscribed
    this.stopAutoUpdate();
    this.isAutoUpdateOn = true;
    this.autoUpdateSubscription = timer(
      this.autoUpdateSeconds * 1000, this.autoUpdateSeconds * 1000
    ).subscribe(this.reloadCallback);
  }

  stopAutoUpdate(): void {
    this.isAutoUpdateOn = false;
    if (this.autoUpdateSubscription) {
      try {
        this.autoUpdateSubscription.unsubscribe();
      } catch (e) {
        alert("error stopping auto-update");
      }
      this.autoUpdateSubscription = null;
    }
  }

  setLastNMinutes(newLastNMinutes: string): void {
    this.lastNMinutes = newLastNMinutes;
    this.reloadCallback();
  }

  /**
   * Use the currently stored this.lastNMinutes to recompute start and end times
   */
  recomputeTimeRange(): void {
    if (this.lastNMinutes > '0') {
      const now = Date.now();
      const end = new Date(now);
      const startMs = now - (parseInt(this.lastNMinutes, 10) * 60 * 1000);
      const start = new Date(startMs);
      try {
        this.startTime = this.formatUtc(start.toISOString());
      } catch (e) {
        this.startTime = null;
      }
      try {
        this.endTime = this.formatUtc(end.toISOString());
      } catch (e) {
        this.endTime = null;
      }
    }
    this.saveToLocalStorage();
  }

  private formatUtc(timestamp: string): string {
    if (timestamp) {
      return timestamp.split('.')[0] + 'Z';
    }
  }

  private saveToLocalStorage(): void {
    if (this.pageName && this.viewName) {
      // console.info(`saving ${this.lastNStorageKey()}`);
      if (this.lastNMinutes === null || this.lastNMinutes === "" || this.lastNMinutes === "null") {
        localStorage.removeItem(this.lastNStorageKey());
      } else {
        localStorage.setItem(this.lastNStorageKey(), this.lastNMinutes);
      }
      if (this.startTime === null || this.startTime === "" || this.startTime === "null") {
        localStorage.removeItem(this.startStorageKey());
      } else {
        localStorage.setItem(this.startStorageKey(), this.startTime);
      }
      if (this.endTime === null || this.endTime === "" || this.endTime === "null") {
        localStorage.removeItem(this.endStorageKey());
      } else {
        localStorage.setItem(this.endStorageKey(), this.endTime);
      }
      if (this.isAutoUpdateOn === null) {
        localStorage.removeItem(this.isAutoUpdateOnStorageKey());
      } else {
        localStorage.setItem(this.isAutoUpdateOnStorageKey(), String(this.isAutoUpdateOn));
      }
    }
  }

  private loadFromLocalStorage(): void {
    if (this.pageName && this.viewName) {
      // console.info(`loading ${this.pageName}-${this.viewName}`);
      if (this.rangeSearchFeature?.enabled) {
        this.lastNMinutes = localStorage.getItem(this.lastNStorageKey());
        this.startTime = localStorage.getItem(this.startStorageKey());
        if (this.startTime === "null" || this.startTime === "") {
          this.startTime = null;
        }
        this.endTime = localStorage.getItem(this.endStorageKey());
        if (this.endTime === "null" || this.endTime === "") {
          this.endTime = null;
        }
      }

      if (this.autoRefreshFeature?.enabled) {
        const autoUpdateFromStorage = localStorage.getItem(this.isAutoUpdateOnStorageKey());
        if (autoUpdateFromStorage) {
          // if any value is set, compute that
          this.isAutoUpdateOn = ("true" === localStorage.getItem(this.isAutoUpdateOnStorageKey()));
        } else {
          // else use the default
          this.isAutoUpdateOn = this.autoRefreshFeature.defaultOn;
        }
      } else {
        this.isAutoUpdateOn = false;
      }
    }
  }

  private lastNStorageKey(): string | null {
    if (this.pageName && this.viewName) {
      return `${this.pageName}-${this.viewName}-lastN`;
    }
    return null;
  }

  private startStorageKey(): string | null {
    if (this.pageName && this.viewName) {
      return `${this.pageName}-${this.viewName}-start`;
    }
    return null;
  }

  private endStorageKey(): string | null {
    if (this.pageName && this.viewName) {
      return `${this.pageName}-${this.viewName}-end`;
    }
    return null;
  }

  private isAutoUpdateOnStorageKey(): string | null {
    if (this.pageName && this.viewName) {
      return `${this.pageName}-${this.viewName}-isAutoUpdateOn`;
    }
    return null;
  }

}
