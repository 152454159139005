import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UUID} from 'angular2-uuid';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ContextApiService} from '../context-api.service';
import * as jsonStableStringify from 'json-stable-stringify';
import {AppComponent} from "../../../app.component";
import {ContextRegistrationWrapper} from "../context-models";

@Component({
  selector: 'ae-context-editor',
  templateUrl: './context-editor.component.html',
  styleUrls: ['./context-editor.component.scss']
})
export class ContextEditorComponent implements OnInit, OnDestroy {
  ctxRegWrappers: ContextRegistrationWrapper[] = [];
  currentContextId: any = null;
  currentContext: any = null;
  currentContextHistory: any = null;
  contextJson: any = null;
  editorOptions = new JsonEditorOptions();
  historyOptions = new JsonEditorOptions();
  viewHistory = false;
  routeParams: Subscription;

  @ViewChild('editor', {static: false}) editor: JsonEditorComponent;
  @ViewChild('history', {static: false}) history: JsonEditorComponent;

  constructor(
    private contextApi: ContextApiService,
    private appComponent: AppComponent,
    private router: Router,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.routeParams = this.route.queryParams.subscribe((params) => {
      if (params.contextId) {
        this.currentContextId = params.contextId;
        this.editContext(params.contextId);

        if (params.history) {
          this.viewHistory = params.history === 'true';
          if (this.viewHistory === true) {
            this.getContextHistory();
          }
        }
      }
    });

    this.getAllContexts();
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.mode = 'code';
    this.editorOptions.modes = ['code', 'tree'];

    this.historyOptions = new JsonEditorOptions();
    this.historyOptions.mode = 'view';
    this.historyOptions.modes = ['code', 'view'];
  }

  ngOnDestroy(): void {
    if (this.routeParams) {
      this.routeParams.unsubscribe();
    }
  }

  getAllContexts(): void {
    this.contextApi.getRegistrationCandidates().subscribe((ctxRegWrappers) => {
        console.log(ctxRegWrappers);
        this.ctxRegWrappers = ctxRegWrappers;
      },
      error => {
        this.appComponent.showError("Fetch contexts error", error)
      });
  }

  loadContext(ctxId: UUID): void {
    this.router.navigate(
      ['context-editor'],
      {
        queryParams: {contextId: ctxId},
        queryParamsHandling: 'merge'
      }
    ).then(() => {
      this.editContext(ctxId);
    });
  }

  editContext(contextId: UUID): void {
    console.log('editContext', contextId);
    this.contextApi.getContext(contextId).subscribe((context) => {
        this.currentContextId = contextId;
        this.currentContext = context;
        let orderedJsonStr = jsonStableStringify(context, {space: 2})
        this.contextJson = JSON.parse(orderedJsonStr);
      },
      error => {
        this.appComponent.showError(`Error getting context '${contextId}'`, error)
      });
  }

  updateContext(): void {
    this.contextApi.updateContext(this.editor.get()).subscribe(
      (context) => {
        this.currentContext = context;
        let orderedJsonStr = jsonStableStringify(context, {space: 2})
        this.contextJson = JSON.parse(orderedJsonStr);
      },
      error => {
        this.appComponent.showError(`Error updating context '${this.currentContextId}'`, error)
      });
  }

  cloneContext(): void {
    if (this.currentContext) {
      const contextId = this.currentContext.contextId
      const contextName = this.currentContext.contextInfo?.contextName || "UNNAMED"
      if (confirm(`Clone new context from '${contextName}' (id: ${contextId}) ???`)) {
        const cloneRequest = {
          sourceContextId: contextId,
          name: `CLONED FROM -- ${contextName}`,
          description: `CLONED FROM -- ${contextName}`
        }
        console.log(cloneRequest);
        this.contextApi.cloneContext(cloneRequest).subscribe((newContext) => {
            this.getAllContexts()
            this.editContext(newContext.contextId);
          },
          error => {
            this.appComponent.showError(`Error cloning context '${contextId}'`, error)
          }
        );
      }
    }
  }

  toggleHistory(): void {
    this.viewHistory = !this.viewHistory;
    this.router.navigate(['context-editor'], {
      queryParams: {history: this.viewHistory},
      queryParamsHandling: 'merge'
    });

    if (this.viewHistory === true) {
      this.getContextHistory();
    }
  }

  getContextHistory(): void {
    if (this.currentContextId) {
      this.contextApi.getHistory(this.currentContextId).subscribe(
        history => {
          let orderedJsonStr = jsonStableStringify(history, {space: 2})
          this.currentContextHistory = JSON.parse(orderedJsonStr);
        },
        error => {
          this.appComponent.showError(
            `Error getting context history '${this.currentContextId}'`,
            error
          )
        });
    }
  }

  deleteContext(): void {
    if (this.currentContext) {
      const contextId = this.currentContext.contextId
      const contextName = this.currentContext.contextInfo?.contextName || "UNNAMED"
      if (confirm(`DELETE context '${contextName}' (id: ${contextId}) ???`)) {
        this.contextApi.deleteContext(contextId).subscribe(() => {
            this.closeEditor();
          },
          error => {
            this.appComponent.showError(`Error deleting context '${contextId}'`, error)
          }
        );
      }
    }
  }

  closeEditor(): void {
    this.getAllContexts();
    this.currentContextId = null;
    this.currentContext = null;
    this.contextJson = null;
    this.currentContextHistory = null;
    this.router.navigate(['context-editor'], {queryParams: {history: this.viewHistory}});
  }

  formatUtc(timestamp: string): string {
    if (timestamp) {
      return timestamp.split('.')[0] + 'Z';
    }
  }
}
