import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from "../base/base.service";

@Injectable()
export class RecordApiService {

  constructor(private baseSvc: BaseService, private http: HttpClient) {
  }

  public computePreviewUrl(previewImageId: string): string {
    return this.baseSvc.projectUrl(`assets/${previewImageId}`);
  }

  public computeFpUrl(fpId: string): string {
    return this.baseSvc.projectUrl(`assets/featureprint/${encodeURIComponent(fpId)}/download`);
  }


}
