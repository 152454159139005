<div class="ae-container-wrapper">
  <div *ngIf="!selectedInstanceDetail && !pendingNewInstance" class="ae-container-wrapper">
    <mat-toolbar color="primary">
      <div class="manager-toolbar row" #toolbar>
        <div class="col-2">
          <mat-card-actions>
            <button mat-icon-button (click)="registerNewInstanceEditor()">
              <strong>NEW ACDC CONFIG</strong>
              <mat-icon size="50">create</mat-icon>
            </button>
          </mat-card-actions>
        </div>
      </div>
    </mat-toolbar>
    <h2>ACDC Management</h2>
    <h4>Last updated {{this.statusAgeSec}}s ago</h4>
    <table class="table table-sm table-dark table-hover table-striped">
      <thead>
      <tr>
        <th>ACDC Instance ID</th>
        <th>ACDC Instance Name</th>
        <th>ACDC URL</th>
        <th>ACDC Status</th>
        <th>Context Name (and ID)</th>
        <th>FP Memory Count</th>
        <th>FP DB Count</th>
        <th>Created</th>
        <th>Last Modified</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let d of instanceDetails; index as i">
        <td><a class="link-active"
               (click)="selectInstance(d.acdcInstanceConfig.instanceId)">{{d.acdcInstanceConfig.instanceId}}</a>
        </td>
        <td>{{d.acdcInstanceConfig.instanceName}}</td>
        <td>{{d.acdcInstanceConfig.uri}}</td>
        <td>{{d.status}}</td>
        <td *ngIf="d.contextInfo">{{d.contextInfo.contextName}} ({{d.contextInfo.contextId}})</td>
        <td *ngIf="!d.contextInfo"></td>
        <td>{{d.sizes?.memoryCount}}</td>
        <td>{{d.sizes?.dbCount}}</td>
        <td>{{formatUtc(d.acdcInstanceConfig.created)}}</td>
        <td>{{formatUtc(d.acdcInstanceConfig.lastModified)}} by {{d.acdcInstanceConfig.modifiedBy}}</td>
      </tr>
      </tbody>
    </table>

  </div>

  <div *ngIf="selectedInstanceDetail" class="ae-container-wrapper">
    <div class="content col-6">
      <mat-card class="mat-elevation-z4">
        <mat-card-header>
          <mat-card-title>ACDC
            Instance: {{selectedInstanceDetail?.acdcInstanceConfig.instanceId}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <table class="table table-sm table-bordered">
            <tr>
              <td><h2>NAME</h2></td>
              <td>
                <input class="name-input" [(ngModel)]="pendingInstanceName" autocomplete="off"/>
              </td>
              <td>
                <button id="updateInstanceName" mat-raised-button
                        (click)="updateInstanceName()"
                        [disabled]="pendingInstanceName == selectedInstanceDetail?.acdcInstanceConfig?.instanceName">
                  Update
                </button>
              </td>
            </tr>
            <tr>
              <td><h2>ACDC URL</h2></td>
              <td>
                <input class="uri-input" [(ngModel)]="pendingInstanceUri" autocomplete="off"/>
              </td>
              <td>
                <button id="updateInstanceUri" mat-raised-button
                        (click)="updateInstanceUri()"
                        [disabled]="pendingInstanceUri == selectedInstanceDetail?.acdcInstanceConfig?.uri">
                  Update
                </button>
              </td>
            </tr>
            <tr>
              <td><h2>CONTEXT</h2></td>
              <td>
                {{selectedInstanceDetail?.contextInfo?.contextName}}
              </td>
            </tr>
            <tr>
              <td><h2>FP DB COUNT</h2></td>
              <td>
                {{selectedInstanceDetail?.sizes?.dbCount}}
              </td>
            </tr>
            <tr>
              <td><h2>FP MEMORY COUNT</h2></td>
              <td>
                {{selectedInstanceDetail?.sizes?.memoryCount}}
              </td>
            </tr>
            <tr>
              <td><h2>STATUS</h2></td>
              <td>
                {{selectedInstanceDetail?.status}}
              </td>
            </tr>
          </table>
        </mat-card-content>

        <mat-card-actions>
          <button mat-raised-button (click)="closeEditors()">CLOSE</button>
          <button mat-button (click)="deleteConfig()">REMOVE ACDC CONFIG</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

  <div *ngIf="pendingNewInstance" class="ae-container-wrapper">
    <div class="content col-6">
      <mat-card class="mat-elevation-z4">
        <mat-card-header>
          <mat-card-title>Register New ACDC Instance</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <table class="table table-sm table-bordered">
            <tr>
              <td><h2>NAME</h2></td>
              <td>
                <input class="name-input" [(ngModel)]="pendingNewInstance.name" autocomplete="off"/>
              </td>
            </tr>
            <tr>
              <td><h2>ACDC URL</h2></td>
              <td>
                <input class="uri-input" [(ngModel)]="pendingNewInstance.uri" autocomplete="off"/>
              </td>
            </tr>
          </table>
        </mat-card-content>

        <mat-card-actions>
          <button mat-raised-button (click)="closeEditors()">CLOSE</button>
          <button mat-button (click)="createNewConfig()"
                  [disabled]="!pendingNewInstance?.name || !pendingNewInstance?.uri">
            SAVE ACDC CONFIG
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
