import {ContextInfo, RefsetSize} from "./context-models";



export class AcdcInstanceConfig {
  public instanceId: string;
  public instanceName: string;
  public uri: string;
  public data: any;
  public created: string;
  public createdBy: string;
  public lastModified: string;
  public modifiedBy: String;
}

export class AcdcInstanceRegistration {
  public contextId: string
  public acdcInstanceConfig: AcdcInstanceConfig
  public shardIndex: number
  public created: string
  public createdBy: string
  public lastModified: string
  public modifiedBy: string
}

export class AcdcRegistrationDetails {
  public acdcInstanceConfig: AcdcInstanceConfig;
  public contextInfo: ContextInfo;
  public sizes: RefsetSize;
  public status: string;
}

export class CreateAcdcConfigRequest {
  constructor(
    public name: string,
    public uri: string
  ) {
  }
}


export class RenameAcdcConfigRequest {
  constructor(
    public instanceId: string,
    public newName: string
  ) {
  }
}

export class UpdateAcdcConfigUriRequest {
  constructor(
    public instanceId: string,
    public uri: string
  ) {
  }
}

