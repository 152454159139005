import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

  public title = "ERROR";
  public message = "";
  public details = "";

  constructor(
    private dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data?.title || this.title;
    this.message = data?.message || this.message;
    this.details = data?.details || this.details;

  }

  ngOnInit(): void {
  }


  close(): void {
    this.dialogRef.close();
  }

}
