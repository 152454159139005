<h1 mat-dialog-title>Alert States</h1>
<div mat-dialog-content>
  <p>Select States to Exclude</p>
  <mat-form-field appearance="fill">
    <mat-label>States</mat-label>
    <mat-select multiple [(value)]="data.excluded">
      <mat-option *ngFor="let state of data.states" [value]="state">{{state}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button [mat-dialog-close]="data.excluded" cdkFocusInitial>Ok</button>
</div>
