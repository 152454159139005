import {Injectable, OnDestroy} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {env} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {UUID} from 'angular2-uuid';
import {catchError, map, tap} from "rxjs/operators";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {ContextInfo, ContextRegistrationWrapper, RefsetSize} from "./context-models";
import {throwError} from "rxjs";
import {Subscription} from "rxjs/internal/Subscription";
import {BaseService} from "../base/base.service";
import {UserService} from "../user/user.service";

const selectedContextIdKey = "selectedContextId";

@Injectable()
export class ContextApiService implements OnDestroy {
  private readonly currentContextIdStream: BehaviorSubject<string>;
  public readonly contextInfoStream: BehaviorSubject<ContextInfo[]> = new BehaviorSubject<ContextInfo[]>([]);

  private currentUserSub: Subscription;

  constructor(
    private userService: UserService,
    private baseSvc: BaseService,
    private http: HttpClient
  ) {
    this.currentContextIdStream = new BehaviorSubject<string>(this.computeInitialContextId());

    // TODO migrate
    this.currentUserSub = this.userService.currentUser().subscribe(
      userInfo => {
        if (userInfo) {
          this.getAllContextInfos().subscribe(ctxInfos => this.contextInfoStream.next(ctxInfos));
        } else {
          this.contextInfoStream.next([]);
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.currentUserSub) {
      this.currentUserSub.unsubscribe();
      this.currentUserSub = null;
    }
  }

  public getCurrentContextId(): Observable<string> {
    return this.currentContextIdStream;
  }

  public setCurrentContextId(contextId: string): void {
    this.currentContextIdStream.next(contextId);
    if (contextId) {
      localStorage.setItem(selectedContextIdKey, contextId);
    }
  }

  private computeInitialContextId(): string | null {
    const initialContextId = localStorage.getItem(selectedContextIdKey);
    if (!initialContextId) {
      this.contextInfoStream.subscribe(
        ctxInfos => {
          if (ctxInfos.length > 0) {
            this.setCurrentContextId(ctxInfos[0].contextId);
          }
        }
      );
    }
    return initialContextId;
  }

  public getRegisteredContexts(): Observable<any[]> {

    const url = this.baseSvc.projectUrl(`conduit/admin/ctx-editor/registered/list`);
    const req: Observable<any> = this.http
      .get(url, {observe: 'body', headers: this.baseSvc.defaultHeaders()})
      .pipe(
        map(body => (body as any[]).map(x => x) as ContextRegistrationWrapper[],
          catchError(error => {
            return throwError("Incorrect ContextRegistrationWrapper[] JSON");
          }))
      );

    return req;
  }

  public getRegistrationCandidates(): Observable<ContextRegistrationWrapper[]> {

    const url = this.baseSvc.projectUrl(`conduit/admin/ctx-editor/all/list`);
    const req: Observable<any> = this.http
      .get(url, {observe: 'body', headers: this.baseSvc.defaultHeaders()})
      .pipe(
        tap(body => {
          // Go ahead and tap into the results and update the list of known contextInfos
          this.contextInfoStream.next((body as any[]).map(x => x.contextWrapper.contextInfo) as ContextInfo[]);
        }),
        map(body => (body as any[]).map(x => x) as ContextRegistrationWrapper[],
          catchError(error => {
            return throwError("Incorrect ContextRegistrationWrapper[] JSON");
          }))
      );

    return req;
  }

  private getAllContextInfos(): Observable<ContextInfo[]> {
    const url = this.baseSvc.projectUrl(`conduit/admin/ctx-editor/all/list`);
    const req: Observable<any> = this.http
      .get(url, {observe: 'body', headers: this.baseSvc.defaultHeaders()})
      .pipe(
        map(body => (body as any[]).map(
          x => x.contextWrapper.contextInfo) as ContextInfo[],
          catchError(error => {
            return throwError("Incorrect ContextInfo[] JSON");
          }))
      );

    return req;
  }

  public getActivationCandidates(): Observable<ContextRegistrationWrapper[]> {

    const url = this.baseSvc.projectUrl(`conduit/admin/refset-mgr/all/list`);
    const req: Observable<any> = this.http
      .get(url, {observe: 'body', headers: this.baseSvc.defaultHeaders()})
      .pipe(
        map(body => (body as any[]).map(x => x) as ContextRegistrationWrapper[],
          catchError(error => {
            return throwError("Incorrect ContextRegistrationWrapper[] JSON");
          }))
      );

    return req;
  }
  public getContext(contextId: UUID): Observable<any> {

    const url = this.baseSvc.projectUrl(`conduit/admin/ctx-manager/${contextId}`);
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions());

    return req;
  }

  public getRefSetSize(contextId: UUID): Observable<RefsetSize> {

    const url = this.baseSvc.projectUrl(`conduit/admin/refset-mgr/${contextId}/size`);
    const req: Observable<any> = this.http
      .get(url, {observe: 'body', headers: this.baseSvc.defaultHeaders()})
      .pipe(
        map(body => body as RefsetSize)
      );

    return req;
  }


  public getHistory(contextId: UUID): Observable<any[]> {

    const url = this.baseSvc.projectUrl(`conduit/admin/ctx-editor/${contextId}/history`);
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions());

    return req;
  }

  public updateContext(context: any): Observable<any> {

    const url = this.baseSvc.projectUrl(`conduit/admin/ctx-editor/${context.contextId}`);
    const formData = new FormData();
    formData.append(
      'conduit-request-details',
      new Blob([JSON.stringify(context)], {type: 'application/json'}));
    const req: Observable<any> = this.http
      .put(url, formData, {headers: this.baseSvc.multipartHeaders()});

    return req;
  }

  public deleteContext(contextId: UUID): Observable<any> {

    const url = this.baseSvc.projectUrl(`conduit/admin/ctx-manager/${contextId}`);
    const req: Observable<any> = this.http
      .delete(url, this.baseSvc.defaultOptions());

    return req;
  }

  public cloneContext(cloneRequest: any): Observable<any> {

    const url = this.baseSvc.projectUrl(`conduit/admin/ctx-manager/clone`);
    const formData = new FormData();
    formData.append(
      'conduit-request-details',
      new Blob([JSON.stringify(cloneRequest)], {type: 'application/json'}));
    const req: Observable<any> = this.http
      .put(url, formData, {headers: this.baseSvc.multipartHeaders()});

    return req;
  }


  public activateContext(contextId: UUID): Observable<any> {

    const url = this.baseSvc.projectUrl(`conduit/admin/refset-mgr/${contextId}/activate`);
    const req: Observable<any> = this.http.put(url, this.baseSvc.defaultOptions());

    return req;
  }

  public deactivateContext(contextId: UUID): Observable<any> {

    const url = this.baseSvc.projectUrl(`conduit/admin/refset-mgr/${contextId}/deactivate`);
    const req: Observable<any> = this.http.put(url, this.baseSvc.defaultOptions());

    return req;
  }

  public clearContext(contextId: UUID): Observable<any> {
    console.log("clear-context", contextId);

    const url = this.baseSvc.projectUrl(`conduit/admin/ctx-manager/clear`);
    const clearRequest = {contextId: contextId};

    const formData = new FormData();
    formData.append(
      'conduit-request-details',
      new Blob([JSON.stringify(clearRequest)], {type: 'application/json'}));

    const req: Observable<any> = this.http
      .put(url, formData, {headers: this.baseSvc.multipartHeaders()});

    return req;
  }

}
