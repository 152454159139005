export class DataViewDefinition {
  public key: string;
  public name: string;
  public queryFields: FieldDefinition[];
  public summaryFields: FieldDefinition[];
  public tableFields: FieldDefinition[];
  public rowDetails: RowDetails | null;
  public features: Feature[];
  public requiredRole: string | null;


  constructor(
    key: string,
    name: string,
    queryFields: FieldDefinition[],
    summaryFields: FieldDefinition[],
    tableFields: FieldDefinition[],
    rowDetails: RowDetails | null,
    features: Feature[],
    requiredRole: string | null
  ) {
    this.key = key;
    this.name = name;
    this.queryFields = queryFields;
    this.summaryFields = summaryFields;
    this.tableFields = tableFields;
    this.rowDetails = rowDetails;
    this.features = features;
    this.requiredRole = requiredRole;
  }

  public static fromObject(untyped: object): DataViewDefinition {
    const typed = untyped as DataViewDefinition;
    return new DataViewDefinition(
      typed.key as string,
      typed.name as string,
      typed.queryFields as FieldDefinition[],
      typed.summaryFields as FieldDefinition[],
      typed.tableFields as FieldDefinition[],
      typed.rowDetails as RowDetails,
      typed.features as Feature[],
      typed.requiredRole as string | null
    );
  }

  public static fromObjectArray(untyped: object[]): DataViewDefinition[] {
    return untyped.map((o) => DataViewDefinition.fromObject(o));
  }


  public getAutoRefreshFeature(): AutoRefreshFeature | null {
    const baseFeature = this.getFeature("autoRefresh");
    if (baseFeature) {
      return new AutoRefreshFeature(
        parseInt(baseFeature.options[0] || "10", 10) || 10,
        baseFeature.options[1] === 'true'
      );
    }
    return null;
  }

  public getRangeSearchFeature(): RangeSearchFeature | null {
    const baseFeature = this.getFeature("rangeSearch");
    if (baseFeature) {
      return new RangeSearchFeature();
    }
    return null;
  }

  public getDownloadCsvFeature(): DownloadCsvFeature | null {
    const baseFeature = this.getFeature("downloadCsv");
    if (baseFeature) {
      return new DownloadCsvFeature();
    }
    return null;
  }

  public getEnableRowFeature(): EnableRowFeature | null {
    const baseFeature = this.getFeature("enableRow");
    if (baseFeature) {
      return new EnableRowFeature();
    }
    return null;
  }

  public getDisableRowFeature(): DisableRowFeature | null {
    const baseFeature = this.getFeature("disableRow");
    if (baseFeature) {
      return new DisableRowFeature();
    }
    return null;
  }

  public getShowTableFeature(): ShowTableFeature | null {
    const baseFeature = this.getFeature("hideTable");
    if (baseFeature) {
      return null;
    }
    return new ShowTableFeature();
  }


  private getFeature(featureKey: string): Feature | null {
    return this.features.find((f) => f.key === featureKey);
  }
}


export class RowDetails {
  public queryFields: FieldDefinition[];
  public detailFields: FieldDefinition[];
}


class FieldDefinition {
  public key: string;
  public name: string;
  public datatype: string;
}

export interface DataViewRow {
  status: string;
}

export class DataViewResult {
  public rows: DataViewRow[];
  public summary: DataViewRow;
}


export class Feature {
  public key: string;
  public options: string[];
}


export class AutoRefreshFeature {
  public intervalSec = 10;
  public defaultOn = false;
  public enabled = true;

  constructor(intervalSec: number, defaultOn: boolean) {
    this.intervalSec = intervalSec;
    this.defaultOn = defaultOn;
  }
}


export class RangeSearchFeature {
  public enabled = true;
}

export class DownloadCsvFeature {
  public enabled = true;
}


export class EnableRowFeature {
  public enabled = true;
}

export class DisableRowFeature {
  public enabled = true;
}

export class ShowTableFeature {
  public enabled = true;
}
