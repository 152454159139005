import {AppConfig} from "./app-config";

export interface IConfigService<T extends AppConfig> {
  setConfig(config: T): void;

  getConfig(): T
}

export class ConfigService<T extends AppConfig> implements IConfigService<T> {
  private config;

  constructor() {
  }

  setConfig(config: T) {
    this.config = config;
  }

  getConfig(): T {
    return this.config;
  }
}

