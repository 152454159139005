import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {timer} from 'rxjs/internal/observable/timer';
import {Router} from "@angular/router";
import {UtilsApiService} from "./modules/api/utils-api.service";
import {BuildInfo} from "./modules/api/build-info";
import {DataViewApiService} from "./modules/data-viewer/data-view-api.service";
import {Observable} from "rxjs/internal/Observable";
import {ContextApiService} from "./modules/contexts/context-api.service";
import {ContextInfo} from "./modules/contexts/context-models";

import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ErrorDialogComponent} from "./modules/error-dialog/error-dialog.component";
import {UserService} from "./modules/user/user.service";
import {UserInfo} from "./modules/user/user-info";
import {ConfigService} from "./modules/base/config.service";
import {AdminConfig} from "./app.module";

@Component({
  selector: 'ae-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(
    private utilsApi: UtilsApiService,
    private contextApi: ContextApiService,
    private dataViewApi: DataViewApiService,
    private userService: UserService,
    public config: ConfigService<AdminConfig>,
    private dialog: MatDialog,
    private router: Router
  ) {
  }

  private accessSub: Subscription;
  private isAdminSub: Subscription;
  private currentUserSub: Subscription;

  public refsetManagerNavItem = {name: 'Refset Manager', route: '/refset-manager'};
  public acdcManagerNavItem = {name: 'ACDC Manager', route: '/acdc-manager'};
  public contextEditingNavItem = {name: 'Context Editing', route: '/context-editor'};
  public configAssetsNavItem = {name: 'Config Asset Editing', route: '/config-assets'};
  public serverConfigNavItem = {name: 'Server Config Editing', route: '/server-config'};
  public logoutNavItem = {name: 'Logout', route: '/logout'};
  public stationStatusNavItem = {name: 'Station Status', route: '/station-status'};
  public stationConfigNavItem = {name: 'Station Config', route: '/station-config'};


  public navItems = [
    this.refsetManagerNavItem,
    this.acdcManagerNavItem,
    this.contextEditingNavItem,
    this.configAssetsNavItem,
    this.stationStatusNavItem,
    this.stationConfigNavItem,
    this.logoutNavItem
  ];

  public title: string = this.config.getConfig()?.appSettings?.title;
  public username = "";
  public isAdmin = false;
  public currentUser: UserInfo | null;

  public now: any = new Date();
  private timerSub: Subscription;
  private contextInfosSub: Subscription;
  public appVersion: string;
  public allContextInfos: ContextInfo[] = [];

  ngOnInit(): void {
    const t = timer(1, 1000);
    this.timerSub = t.subscribe(() => {
      this.now = (new Date()).toISOString().split('.')[0];
    });

    this.contextInfosSub = this.contextApi.contextInfoStream.subscribe(
      ctxInfos => {
        this.allContextInfos = ctxInfos;
        this.allContextInfos.sort((a, b) => {
          const aName = a.contextName.toUpperCase();
          const bName = b.contextName.toUpperCase();
          if (aName > bName) {
            return 1;
          } else if (aName < bName) {
            return -1;
          } else {
            return 0;
          }
        });
      },
      error => {
        this.showError("Error reading context list", error);
      }
    );

    this.isAdminSub = this.userService.isAdmin().subscribe(isAdmin => {
      this.isAdmin = true;
      this.recomputeNavItems();
    });

    this.currentUserSub = this.userService.currentUser().subscribe(curUser => {
      this.currentUser = curUser;
      this.username = curUser?.username;
    });
  }

  ngOnDestroy(): void {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
      this.timerSub = null;
    }

    if (this.accessSub) {
      this.accessSub.unsubscribe();
      this.accessSub = null;
    }

    if (this.contextInfosSub) {
      this.contextInfosSub.unsubscribe();
      this.contextInfosSub = null;
    }

    if (this.isAdminSub) {
      this.isAdminSub.unsubscribe();
      this.isAdminSub = null;
    }

    if (this.currentUserSub) {
      this.currentUserSub.unsubscribe();
      this.currentUserSub = null;
    }
  }

  openFeedbackDialog(
    message: string,
    title: string,
    details: string | null = null,
    stacktrace: boolean = false
  ): void {
    if (stacktrace) {
      // console.trace(title);
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title,
      message,
      details
    };

    this.dialog.open(ErrorDialogComponent, dialogConfig);
  }

  showError(
    message: string,
    error: any,
    stacktrace: boolean = false
  ): void {
    if (this.currentUser && error?.status !== 401) {
      let errorDetails: string | null = null;
      if (typeof error === 'string') {
        errorDetails = error;
      } else {
        errorDetails = "";
        for (const key in error) {
          if (error.hasOwnProperty(key)) {
            errorDetails = `${errorDetails}\n${key} => ${error[key]}`;
          }
        }
      }
      this.openFeedbackDialog(
        message, "ERROR", errorDetails, stacktrace
      );
    }
  }

  showWarning(
    message: string,
    error: any = null,
    stacktrace: boolean = false
  ): void {
    if (this.currentUser && error?.status !== 401) {
      let errorDetails: string | null = null;
      if (typeof error?.status === 'string') {
        errorDetails = error?.status;
      } else if (error) {
        errorDetails = "";
        for (const key in error) {
          if (error.hasOwnProperty(key)) {
            errorDetails = `${errorDetails}\n${key} => ${error[key]}`;
          }
        }
      }
      this.openFeedbackDialog(
        message, "WARNING", errorDetails, stacktrace
      );
    }
  }

  recomputeNavItems(): void {
    this.dataViewApi.getDefinitions().subscribe(
      (definitions) => {
        try {
          let newNavItems = [];
          if (this.isAdmin) {
            newNavItems = definitions.map((d) => {
              return {name: d.name, route: `/data-viewer/${d.key}`};
            });

            newNavItems.push(this.refsetManagerNavItem);
            newNavItems.push(this.acdcManagerNavItem);
            if (! this.config.getConfig().ipSecurityEnabled) {
              newNavItems.push(this.contextEditingNavItem);
            }
            newNavItems.push(this.configAssetsNavItem);
            newNavItems.push(this.stationStatusNavItem);
            newNavItems.push(this.stationConfigNavItem);
            newNavItems.push(this.serverConfigNavItem);
          }
          newNavItems.push(this.logoutNavItem);

          this.navItems = newNavItems;
        } catch (error) {
          this.showError("error building nav items", error);
        }
      },
      // If getting DataViewDefinitions fails, just go ahead and logout
      (error) => {
        this.showError("Cannot load menu navigation items", error);
        const newNavItems = [];
        newNavItems.push(this.logoutNavItem);
        this.navItems = newNavItems;
      }
    );
  }


  clearNavItems(): void {
    this.navItems = [];
  }

  reloadPortal(): void {
    location.reload();
  }

  homeNav(): void {
    // this.showError(
    //   "testing error from homeNav",
    // "some detailed stuff",
    // {message: "some detailed stuff", status: 404},
    // true
    // )
    this.router.navigate(['/']).then(
      (result: boolean) => this.reloadPortal(),
      (rejected) => this.reloadPortal()
    );
  }

  computeAppVersion(): void {
    this.utilsApi.getBuildInfo().subscribe(
      (buildInfo: BuildInfo) => {
        this.appVersion = buildInfo.version;
      },
      (error) => {
        this.appVersion = "ERROR";
        this.showWarning("Version info unavailable");
      }
    );
  }

  getCurrentContextId(): Observable<string> {
    return this.contextApi.getCurrentContextId();
  }

  setCurrentContextId(ctxId: string): void {
    this.contextApi.setCurrentContextId(ctxId);
  }

}
