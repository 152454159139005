<div *ngIf="!currentContext" class="ae-container-wrapper">
  <h2>Context Editing</h2>
  <table class="table table-sm table-dark table-hover table-striped">
    <thead>
    <tr>
      <th>Context ID</th>
      <th>Context Name</th>
      <th>Context Desc</th>
      <th>Created</th>
      <th>Last Modified</th>
      <th>Context Registration</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let crw of ctxRegWrappers; index as i" >
      <td><a class="link-active" (click)="loadContext(crw.contextWrapper.contextId)">{{crw.contextWrapper.contextId}}</a></td>
      <td>{{crw.contextWrapper.contextInfo.contextName}}</td>
      <td>{{crw.contextWrapper.contextInfo.contextDesc}}</td>
      <td>{{formatUtc(crw.contextWrapper.contextInfo.created)}}</td>
      <td>{{formatUtc(crw.contextWrapper.contextInfo.lastModified)}} by {{crw.contextWrapper.contextInfo.modifiedBy}}</td>
      <td>{{crw.status || "UNKNOWN"}}</td>
    </tr>
    </tbody>
  </table>

</div>

<div *ngIf="currentContext" class="content">
  <div class="content">
    <mat-card class="mat-elevation-z4">
      <mat-card-header>
        <mat-card-title>Context</mat-card-title>
        <mat-card-subtitle> {{currentContext.contextId}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <json-editor #editor [options]="editorOptions" [data]="contextJson" style="height: 800px; width:800px;"></json-editor>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button (click)="closeEditor()">CLOSE</button>
        <button mat-button (click)="updateContext()">SAVE</button>
        <button mat-button (click)="toggleHistory()">HISTORY</button>
        <button mat-button (click)="cloneContext()">CLONE</button>
        <button mat-button (click)="deleteContext()">DELETE</button>
      </mat-card-actions>
    </mat-card>
  </div>

  <div *ngIf="viewHistory" class="content">
    <mat-card class="mat-elevation-z4">
      <mat-card-header>
        <mat-card-title>Context History</mat-card-title>
        <mat-card-subtitle> {{currentContext.contextId}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <json-editor #history [options]="historyOptions" [data]="currentContextHistory" style="height: 800px; width:800px;"></json-editor>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button (click)="toggleHistory()">CANCEL</button>
      </mat-card-actions>
    </mat-card>
  </div>

</div>
