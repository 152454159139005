import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {HttpClientModule} from "@angular/common/http";
import {UserModule} from "./user/user.module";
import {UtilityModule} from "./utility/utility.module";
import {BaseModule} from "./base/base.module";
import {AppRoutingModule} from "../app-routing.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    UserModule,
    UtilityModule,
    BaseModule,
    AppRoutingModule
  ],
  providers: [],
  exports: [],
  bootstrap: []
})
export class CoreModule {
}
