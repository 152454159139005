import {Injectable} from "@angular/core";
import {Observable} from "rxjs/internal/Observable";
import {HttpClient} from '@angular/common/http';
import {
  AcdcRegistrationDetails,
  CreateAcdcConfigRequest,
  RenameAcdcConfigRequest,
  UpdateAcdcConfigUriRequest
} from "./acdc-models";
import {catchError, map} from "rxjs/operators";
import {ContextInfo} from "./context-models";
import {throwError} from "rxjs";
import {BaseService} from "../base/base.service";


@Injectable()
export class AcdcApiService {

  constructor(
    private baseSvc: BaseService,
    private http: HttpClient
  ) {
  }

  public getRegisteredInstanceDetails(): Observable<AcdcRegistrationDetails[]> {

    const url = this.baseSvc.projectUrl(`conduit/admin/acdc-manager/instance-registration/list`);
    const req: Observable<any[]> = this.http
      .get(url, {observe: 'body', headers: this.baseSvc.defaultHeaders()})
      .pipe(
        map(body => (body as any[]).map(x => x) as ContextInfo[],
          catchError(error => {
            return throwError("Incorrect AcdcRegistrationDetails[] JSON");
          })
        )
      );

    return req;
  }

  public updateInstanceName(instanceId: string, newName: string): Observable<any> {
    const url = this.baseSvc.projectUrl(`conduit/admin/acdc-manager/config/name`);
    const body = new RenameAcdcConfigRequest(instanceId, newName);
    const formData = new FormData();
    formData.append(
      'conduit-request-details',
      new Blob([JSON.stringify(body)], {type: 'application/json'}));
    const req: Observable<any> = this.http
      .put(url, formData, {headers: this.baseSvc.multipartHeaders()});

    return req;
  }

  public updateInstanceUri(instanceId: string, newUri: string): Observable<any> {
    const url = this.baseSvc.projectUrl(`conduit/admin/acdc-manager/config/uri`);
    const body = new UpdateAcdcConfigUriRequest(instanceId, newUri);
    const formData = new FormData();
    formData.append(
      'conduit-request-details',
      new Blob([JSON.stringify(body)], {type: 'application/json'}));
    const req: Observable<any> = this.http
      .put(url, formData, {headers: this.baseSvc.multipartHeaders()});

    return req;
  }

  public createAcdcConfig(body: CreateAcdcConfigRequest): Observable<any> {
    const url = this.baseSvc.projectUrl(`conduit/admin/acdc-manager/config`);
    const formData = new FormData();
    formData.append(
      'conduit-request-details',
      new Blob([JSON.stringify(body)], {type: 'application/json'}));
    const req: Observable<any> = this.http
      .post(url, formData, {headers: this.baseSvc.multipartHeaders()});

    return req;
  }

  public removeAcdcConfig(instanceId: string): Observable<any> {
    const url = this.baseSvc.projectUrl(`conduit/admin/acdc-manager/config/${instanceId}`);
    const req: Observable<any> = this.http
      .delete(url, this.baseSvc.defaultOptions());

    return req;
  }
}
