import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {HttpClient} from '@angular/common/http';
import {BaseService} from "../base/base.service";
import {map} from "rxjs/operators";

export class StationStatus {
  id: string;
  stationId: string;
  state: string;
  condition: string;
  description: string;
  meta: any;
  created: Date;
}

export interface MonitoredStation {
  stationId: string;
  enabled: boolean;
  dataSet: string;
}

export interface MonitoredStationConfig {
  stations: MonitoredStation[];
}

@Injectable()
export class StationApiService {

  constructor(private baseSvc: BaseService, private http: HttpClient) {
  }

  public getConfig(): Observable<MonitoredStationConfig> {
    const url = this.baseSvc.projectUrl(`conduit/admin/monitoring/device/station-config`);
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions())
      .pipe(map((config: any) => config as MonitoredStationConfig));

    return req;
  }

  public setConfig(config: MonitoredStationConfig): Observable<void> {
    const url = this.baseSvc.projectUrl(`conduit/admin/monitoring/device/station-config`);
    const formData = new FormData();
    formData.append(
      'conduit-request-details',
      new Blob([JSON.stringify(config)], {type: 'application/json'}));
    const req: Observable<any> = this.http
      .put(url, formData, {headers: this.baseSvc.multipartHeaders()});

    return req;
  }

  public getDeviceStatus(): Observable<StationStatus[]> {

    const url = this.baseSvc.projectUrl(`conduit/admin/monitoring/device/status/list`);
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions())

    return req;
  }

  public getDeviceHistory(stationId: string,
                          before: string = null,
                          conditions: string[] = null,
                          excludeStates: string[] = null,
                          limit: number = null
  ): Observable<StationStatus[]> {

    let url = this.baseSvc.projectUrl(`conduit/admin/monitoring/device/status?stationId=${stationId}`);
    if (before) {
      url += '&before=' + before;
    }
    if (conditions) {
      url += '&conditions=' + conditions.join(",");
    }
    if (excludeStates) {
      url += '&excludeStates=' + excludeStates.join(",");
    }
    if (limit) {
      url += '&limit=' + limit;
    }
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions());

    return req;
  }

}
