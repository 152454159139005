<div>
  <mat-toolbar color="primary">
    <div class="manager-toolbar row">
      <div class="col-4">
        Station Status
      </div>
      <div class="col-8">
        <div>
          <div class="text-right hint-tiny">
            Last Updated: {{ getLastChecked()}} seconds ago...
            <button mat-icon-button (click)="refresh();">
              <mat-icon size="50">refresh</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-toolbar>
  <div class="sa-current">
    <h3>Current Status</h3>
    <table class="table table-sm table-hover table-striped">
      <thead>
      <tr>
        <th>Station</th>
        <th>Condition</th>
        <th>State</th>
        <th>Description</th>
        <th>Timestamp</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let s of stationList">
        <tr class="link-active" (click)="getHistory(s.stationId)">
          <td>{{s.stationId}} </td>
          <td [ngClass]="s.condition">{{s.condition}}</td>
          <td>{{s.state}}</td>
          <td>{{s.description}}</td>
          <td>{{s.created}}</td>
        </tr>
        <tr class="link-active" (click)="getHistory(s.stationId)">
          <td>&nbsp; * meta:</td>
          <td colspan="4">
            <pre>{{s.meta | json}}</pre>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
  <div class="sa-history" *ngIf="historyStation">
    <h3>Alert History for {{historyStation}}
      &nbsp;
      <button mat-stroked-button (click)="historyStation = null;">close</button>
      &nbsp; | &nbsp;
      Include Only: <button mat-stroked-button (click)="conditionsDialog();">{{filteredConditions?.join(" | ")}}</button>
      &nbsp; | &nbsp;
      Exclude: <button mat-stroked-button (click)="statesDialog();">{{excludedStates?.join(" | ") || "None"}}</button>

    </h3>
    <table class="table table-sm table-hover table-striped">
      <thead>
      <tr>
        <th>Condition</th>
        <th>State</th>
        <th>Description</th>
        <th>Timestamp</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let h of historyList">
        <tr class="link-active" (click)="toggleMeta(h.id)">
          <td [ngClass]="h.condition">{{h.condition}}</td>
          <td>{{h.state}}</td>
          <td>{{h.description}}</td>
          <td>{{h.created}}</td>
        </tr>
        <tr class="sa-meta link-active" *ngIf="historyMeta.has(h.id)" (click)="toggleMeta(h.id)">
          <td>&nbsp; * meta:</td>
          <td colspan="3">
            <pre>{{h.meta | json}}</pre>
          </td>
        </tr>
      </ng-container>
      <tr>
        <td colspan="4">
          <a *ngIf="!historyEof" class="link-active" (click)="moreHistory();">
            Click for more history
          </a>
          <span *ngIf="historyEof">End of history</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

