import {Injectable, NgModule} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterModule,
  RouterStateSnapshot,
  Routes
} from '@angular/router';
import {RefsetManagerComponent} from './modules/contexts/refset-manager/refset-manager.component';
import {AcdcManagerComponent} from './modules/contexts/acdc-manager/acdc-manager.component';
import {ContextEditorComponent} from './modules/contexts/context-editor/context-editor.component';
import {DataViewerComponent} from "./modules/data-viewer/viewer/data-viewer.component";
import {ServerConfigComponent} from "./modules/server-config/editor/server-config.component";
import {StationStatusComponent} from "./modules/stations/station-status/station-status.component";
import {BaseService} from "./modules/base/base.service";
import {LoginComponent} from "./modules/user/login/login.component";
import {StationConfigComponent} from "./modules/stations/station-config/station-config.component";
import {
  ConfigAssetsEditorComponent
} from "./modules/assets/config-assets-editor/config-assets-editor.component";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private baseSvc: BaseService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    //console.log('auth-guard, token expired:', this.baseSvc.isTokenExpired(), route, state);
    if (!this.baseSvc.isTokenExpired()) {
      return true;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], {queryParams: {redirectUrl: state.url}});
    return false;
  }
}

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'logout',
    component: LoginComponent,
  },
  {
    path: 'refset-manager',
    component: RefsetManagerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'acdc-manager',
    component: AcdcManagerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'context-editor',
    component: ContextEditorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'data-viewer/default',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'data-viewer/:definitionKey',
    component: DataViewerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'config-assets',
    component: ConfigAssetsEditorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'server-config',
    component: ServerConfigComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'station-status',
    component: StationStatusComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'station-config',
    component: StationConfigComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: false})],
  providers: [AuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
