import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'utc'
})
export class UtcPipe implements PipeTransform {

  constructor() {
  }

  private static formatUtc(timestamp: string): string {
    if (timestamp) {
      return timestamp.split('.')[0] + 'Z';
    }
  }

  transform(src: string): string {
    if (src) {
      return UtcPipe.formatUtc(src);
    }
    return "";
  }
}
