
export class SpringPropertyRecord {
  public key: string
  public value: any
  public typeName: string
  public valueDescriptions: string[]
  public propertyOverride: PropertyOverride
}

export class PropertyOverride {
  public value: any
  public created: string
  public createdBy: string
  public lastModified: string
  public modifiedBy: string

  ////////////////////////////////////////////////////////////
  // Rendering-specific fields for real-time editing

  // Value currently in editor field prior to saving it
  public pendingValue: any
}

export class SimplePropertyUpdate {
  public key: string
  public value: any

  constructor(key: string, value: any) {
    this.key = key
    this.value = value
  }
}
