<h1 mat-dialog-title>Alert Conditions</h1>
<div mat-dialog-content>
  <p>Select Conditions to Filter On</p>
  <mat-form-field appearance="fill">
    <mat-label>Conditions</mat-label>
    <mat-select multiple [(value)]="data.selected">
      <mat-option *ngFor="let condition of data.conditions" [value]="condition">{{condition}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button [mat-dialog-close]="data.selected" cdkFocusInitial>Ok</button>
</div>
