import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription, timer} from 'rxjs';
import {UUID} from 'angular2-uuid';
import {ContextApiService} from "../context-api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AppComponent} from "../../../app.component";
import {ContextRegistrationWrapper} from "../context-models";
import {ServerConfigApiService} from "../../server-config/server-config-api.service";

@Component({
  selector: 'ae-auth-acdc',
  templateUrl: './refset-manager.component.html',
  styleUrls: ['./refset-manager.component.scss']
})
export class RefsetManagerComponent implements OnInit, OnDestroy {

  public contexts: ContextRegistrationWrapper[] = [];
  public currentContext: ContextRegistrationWrapper = null;
  public currentStatus: any = null;
  public currentStatusDetails: any = null;
  public currentDbCount: number = -1;
  public currentMemoryCount: number = -1;
  public clearContextEnabled: boolean = false;
  private lastUpdatedEpoch: number = +(new Date());
  public statusAgeSec = 0;
  private reloadContextInterval = 10;
  private reloadSubscription: Subscription;
  private lastUpdatedInterval = 1;
  private lastUpdatedSubscription: Subscription;

  constructor(
    private contextApi: ContextApiService,
    private serverConfigApi: ServerConfigApiService,
    private appComponent: AppComponent,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.getAllContexts();
    this.startTimer();
    this.serverConfigApi.isClearContextEnabled().subscribe(
      (isEnabled) => {
        this.clearContextEnabled = isEnabled
      });
  }

  ngOnDestroy(): void {
    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe();
    }
    if (this.lastUpdatedSubscription) {
      this.lastUpdatedSubscription.unsubscribe();
    }
  }

  startTimer(): void {
    if (!this.reloadSubscription) {
      const refreshTimer = timer(this.reloadContextInterval * 1000, this.reloadContextInterval * 1000);
      this.reloadSubscription = refreshTimer.subscribe(t => {
        this.getAllContexts();
      });
    }
    if (!this.lastUpdatedSubscription) {
      const lastUpdatedTimer = timer(this.lastUpdatedInterval * 1000, this.lastUpdatedInterval * 1000);
      this.lastUpdatedSubscription = lastUpdatedTimer.subscribe(t => {
        let nowEpoch: number = +new Date();
        this.statusAgeSec = Math.ceil((nowEpoch - this.lastUpdatedEpoch) / 1000);
      });
    }
  }

  public activateContext(): void {
    const ctx = this.currentContext;
    const ctxName = ctx?.contextWrapper?.contextInfo?.contextName || "UNTITLED";
    const ctxId = ctx?.contextWrapper?.contextId;
    if (ctxId) {
      if (confirm(`ACTIVATE context ${ctxName} ??? \n\n ID: ${ctxId}`)) {
        this.contextApi.activateContext(ctxId).subscribe(() => {
            //alert('Success. Wait for status to update in the list');
            this.closeManager();
          },
          (error) => {
            this.appComponent.showError(`Error activating context ${ctxId}`, error)
          }
        );
      }
    } else {
      this.appComponent.showWarning("No context selected")
    }
  }


  public deactivateContext(): void {
    const ctx = this.currentContext;
    const ctxName = ctx?.contextWrapper?.contextInfo?.contextName || "UNTITLED";
    const ctxId = ctx?.contextWrapper?.contextId;
    if (ctxId) {
      if (confirm(`DEACTIVATE context ${ctxName}  ??? \n\n ID: ${ctxId}`)) {
        this.contextApi.deactivateContext(ctxId).subscribe(() => {
            //alert('Success! Wait for status to update in the list');
            this.closeManager();
          },
          (error) => {
            this.appComponent.showError(`Error deactivating context ${ctxId}`, error)
          }
        );
      }
    } else {
      this.appComponent.showWarning(`No context selected`)
    }
  }

  public clearContext(): void {
    const ctx = this.currentContext;
    const ctxName = ctx?.contextWrapper?.contextInfo?.contextName || "UNTITLED";
    const ctxId = ctx?.contextWrapper?.contextId;
    if (ctxId) {
      if (confirm(`Proceed with CLEARING the context ${ctxName}  ??? \n\n ID: ${ctxId}`)) {
        this.contextApi.clearContext(ctxId).subscribe(() => {
            //alert('Success! Wait for status to update in the list');
            this.closeManager();
          },
          (error) => {
            if (error.status === 409) {
              this.appComponent.showError(`DEACTIVATE before clearing! Error clearing context ${ctxId}`, error)
            } else {
              this.appComponent.showError(`Error clearing context ${ctxId}`, error)
            }
          }
        );
      }
    }
  }


  getAllContexts(): void {
    this.contextApi.getActivationCandidates().subscribe((contexts) => {
          // console.log("Reloading contexts");
          this.contexts = contexts.sort((a, b) => {
                const ctxIdA = a.contextWrapper.contextId
                const ctxIdB = b.contextWrapper.contextId
                if (ctxIdA < ctxIdB) {
                  return -1;
                }
                if (ctxIdA > ctxIdB) {
                  return 1;
                }
                return 0;
              }
          );
          this.lastUpdatedEpoch = +new Date();
        },
        error => {
          this.appComponent.showError(`Error getting all contexts`, error)
        });
  }

  selectContext(ctxId: UUID): void {
    this.router.navigate(['refset-manager'], {queryParams: {contextId: ctxId}}).then(() => {
      this.manageContext(ctxId);
    });
  }


  manageContext(contextId: UUID): void {
    this.currentContext = this.contexts.find((it) => {
      console.log(it);
      return it.contextWrapper.contextId == contextId
    });
    this.currentStatus = this.currentContext?.status || 'UNREGISTERED';
    this.currentStatusDetails = this.currentContext?.statusDetails || '';
    this.contextApi.getRefSetSize(contextId).subscribe(
      refsetSize => {
        this.currentDbCount = refsetSize.dbCount
        this.currentMemoryCount = refsetSize.memoryCount
      }
    )
  }

  closeManager(): void {
    this.getAllContexts();
    this.currentContext = null;
    this.currentStatus = null;
    this.currentDbCount = -1;
    this.currentMemoryCount = -1;
    this.router.navigate(['refset-manager']);
  }


  formatUtc(timestamp: string): string {
    if (timestamp) {
      return timestamp.split('.')[0] + 'Z';
    }
  }
}
