import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {throwError} from "rxjs";
import {SimplePropertyUpdate, SpringPropertyRecord} from "./server-config-models";
import {BaseService} from "../base/base.service";

@Injectable()
export class ServerConfigApiService {

  constructor(private baseSvc: BaseService, private http: HttpClient) {
  }

  public getConfigNames(): Observable<string[]> {
    const url = this.baseSvc.projectUrl(`conduit/admin/spring-config/list-names`);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json'
    });
    return this.http
      .get(url, {headers})
      .pipe(
        map((names: any) => names as string[]),
        catchError(error => {
          return throwError("Cannot read Spring Config Names");
        })
      );
  }


  public getConfig(name: string): Observable<SpringPropertyRecord[]> {
    const url = this.baseSvc.projectUrl(`conduit/admin/spring-config/${name}`);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json'
    });
    return this.http
      .get(url, {headers})
      .pipe(
        map((records: any) => records as SpringPropertyRecord[]),
        catchError(error => {
          return throwError("Cannot get SpringPropertyRecords");
        })
      );
  }

  public saveProperty(key: string, value: any): Observable<any> {
    const propertyUpdate = new SimplePropertyUpdate(key, value);
    const formData = new FormData();
    formData.append(
      'conduit-request-details',
      new Blob([JSON.stringify(propertyUpdate)], {type: 'application/json'}));
    const url = this.baseSvc.projectUrl(`conduit/admin/spring-config/property`);
    return this.http.put(url, formData, {headers: this.baseSvc.multipartHeaders()});
  }

  public isClearContextEnabled(): Observable<boolean> {
    return this.getConfig("clear-context").pipe(
      map((properties: any) => {
          let result = false;
          (properties as SpringPropertyRecord[]).forEach(
            (prop) => {
              if (prop.key === 'clear-context.strategy') {
                result = (prop.value !== 'OFF');
              }
            });
          return result;
        },
        (error) => {
          throwError(`Error getting clear-context.strategy`, error);
        }
      ));
  }

}
