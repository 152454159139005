import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from "@angular/material/dialog";
import {RefsetManagerComponent} from './modules/contexts/refset-manager/refset-manager.component';
import {AcdcManagerComponent} from './modules/contexts/acdc-manager/acdc-manager.component';
import {
  AngularMaterialModule
} from './modules/angular-material.module/angular-material.module.module';
import {NgJsonEditorModule} from 'ang-jsoneditor';
import {ContextEditorComponent} from './modules/contexts/context-editor/context-editor.component';
import {RecordApiService} from "./modules/data-viewer/record-api.service";
import {RecordApiModule} from "./modules/data-viewer/record-api.module";
import {AssetsApiModule} from "./modules/api/assets-api.module";
import {UtilsApiModule} from "./modules/api/utils-api.module";
import {DataViewerComponent} from "./modules/data-viewer/viewer/data-viewer.component";
import {DataViewApiModule} from "./modules/data-viewer/data-view-api.module";
import {DataViewApiService} from "./modules/data-viewer/data-view-api.service";
import {ErrorDialogComponent} from './modules/error-dialog/error-dialog.component';
import {ServerConfigComponent} from './modules/server-config/editor/server-config.component';
import {ServerConfigApiModule} from "./modules/server-config/server-config-api.module";
import {
  ConditionsDialogComponent,
  StatesDialogComponent,
  StationStatusComponent
} from "./modules/stations/station-status/station-status.component";
import {CoreModule} from "./modules/core.module";
import {UtilityModule} from "./modules/utility/utility.module";
import {ConfigService} from "./modules/base/config.service";
import {HttpClient} from "@angular/common/http";
import {AppConfig} from "./modules/base/app-config";
import {StationConfigComponent} from "./modules/stations/station-config/station-config.component";
import {AssetsModule} from "./modules/assets/assets.module";

export interface AdminConfig extends AppConfig {
  appSettings: {
    title: string;
    conduitVersion: string;
    acdcVersion: string;
    fedVersion: string;
    huxleyVersion: string;
    assetsVersion: string;
  };
  ipSecurityEnabled: boolean;
  authType: string;
}

function initializeApp(configSvc: ConfigService<AdminConfig>, httpClient: HttpClient): () => Promise<boolean> {
  return () => {
    return httpClient
      .get("/assets/config/appConfig.json")
      .toPromise()
      .then((config: AdminConfig) => {
        configSvc.setConfig(config);
        //console.log(configSvc.getConfig());
        return true;
      });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    RefsetManagerComponent,
    AcdcManagerComponent,
    ContextEditorComponent,
    DataViewerComponent,
    ErrorDialogComponent,
    ServerConfigComponent,
    StationStatusComponent,
    StationConfigComponent,
    StatesDialogComponent,
    ConditionsDialogComponent
  ],
  imports: [
    CoreModule,
    BrowserModule,
    NgbModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    AppRoutingModule,
    RecordApiModule,
    AssetsApiModule,
    ServerConfigApiModule,
    UtilsApiModule,
    DataViewApiModule,
    MatIconModule,
    MatDialogModule,
    AngularMaterialModule,
    NgJsonEditorModule,
    UtilityModule,
    AssetsModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService, HttpClient],
      multi: true
    },
    ConfigService,
    RecordApiService,
    DataViewApiService
  ],
  bootstrap: [AppComponent],
  entryComponents: [ErrorDialogComponent]
})
export class AppModule {
}
