import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from "rxjs/operators";
import {throwError} from "rxjs";
import {BaseService} from "../base/base.service";

@Injectable()
export class AssetsApiService {

  constructor(
    private baseSvc: BaseService,
    private http: HttpClient
  ) {
  }

  public downloadFp(fpId: string): Observable<Blob> {

    // tslint:disable-next-line:max-line-length
    const url = this.baseSvc.projectUrl(`/conduit/admin/assets/featureprint/${fpId}/download`);
    const requestOptions = this.baseSvc.defaultOptions({responseType: 'blob' as 'json'});

    const file = this.http.get(url, requestOptions).pipe(
      map((value: any) => value as Blob),
      catchError(error => {
        return throwError(`Could not download FP ${fpId}`);
      })
    );

    return file;
  }


  // public listClientConfigSummaries(): Observable<ConfigSummary[]> {
  //   const url = this.baseSvc.realmUrl(`/conduit/customer/assets/client-config/list`);
  //   return this.http.get(
  //     url, this.baseSvc.defaultOptions()
  //   ).pipe(
  //     map((x: any) => x as ConfigSummary[]),
  //     catchError(error => {
  //       return throwError("Incorrect ConfigSummary[] JSON");
  //     })
  //   );
  // }
  //
  // public getClientConfig(key: string): Observable<any> {
  //   const url = encodeURI(env.rootUrl + `/core/assets/client-config?key=${key}`);
  //   return this.http.get(url, this.baseSvc.defaultOptions());
  // }
  //
  // public setClientConfig(key: string, value: any): Observable<any> {
  //   const url = encodeURI(env.rootUrl + `/core/assets/client-config?key=${key}`);
  //   return this.http.put(url, value, this.baseSvc.defaultOptions());
  // }
  //
  // public deleteClientConfig(key: string): Observable<any> {
  //   const url = encodeURI(env.rootUrl + `/core/assets/client-config?key=${key}`);
  //   return this.http.delete(url, this.baseSvc.defaultOptions());
  // }

}
