<div class="col-8">
  <div class="col-4">
    <h2>Server Config Editing</h2>

    <mat-form-field class="multi-role-form-field">
      <mat-label>Spring Configuration</mat-label>
      <mat-select #configNameSelect
                  (selectionChange)="onSelectConfigName(configNameSelect.value)">
        <mat-option *ngFor="let name of configNames"
                    value="{{name}}">{{name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <div class="col-12" style="padding-top: 20px">
    <mat-expansion-panel *ngFor="let prop of configProperties" expanded="{{prop.key == expandedKey}}" (afterExpand)="afterExpandPanel(prop.key)">
      <mat-expansion-panel-header><h2>{{prop.key}}</h2></mat-expansion-panel-header>
      <table class="table-sm">
        <tr>
          <td><strong>Current Value:</strong></td>
          <td>{{prop.value}}</td>
        </tr>
        <tr>
          <td><strong>Pending Value:</strong></td>
          <td><input class="property-input" type="text" [(ngModel)]="prop.propertyOverride.pendingValue"/>
          </td>
          <td>
            <button mat-raised-button
                    (click)="saveProperty(prop)"
                    [disabled]="prop.propertyOverride?.value == prop.propertyOverride?.pendingValue">
              Save Pending Value
            </button>
          </td>
        </tr>
      </table>
      <div class="col-4">
        <table class="table table-sm table-bordered">
          <tr>
            <td><strong>Property Info:</strong></td>
          </tr>
          <tr>
            <td>Data type:</td>
            <td>{{prop.typeName}}</td>
          </tr>
          <tr>
            <td>Type details:</td>
            <td>
              <div *ngFor="let desc of prop.valueDescriptions">
                <span>{{desc}}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>Last overridden:</td>
            <td><span>{{prop.propertyOverride?.lastModified}}</span></td>
          </tr>
          <tr>
            <td>Last overridden by:</td>
            <td><span>{{prop.propertyOverride?.modifiedBy}}</span></td>
          </tr>
        </table>
      </div>
    </mat-expansion-panel>
  </div>

  <div class="col-2" style="padding-top: 20px">
    <mat-card-actions>
      <button mat-raised-button (click)="reloadConfiguration()">
        <span>Reload</span>
        <mat-icon size="50">refresh</mat-icon>
      </button>
    </mat-card-actions>
  </div>
</div>
