<mat-sidenav-container fullscreen>
  <mat-sidenav #nav>
    <mat-list>
        <h1 *ngIf="username">
          <mat-icon>face</mat-icon>
          {{username}}</h1>
        <a mat-list-item (click)="reloadPortal()">
          <mat-icon>refresh</mat-icon>
          Reload Portal</a>
    </mat-list>
    <mat-divider></mat-divider>
    <mat-nav-list *ngIf="username">
      <div>
        <a *ngFor="let navItem of navItems"
           mat-list-item
           (click)="nav.close()"
           routerLinkActive
           #routerLinkActiveInstance="routerLinkActive"
           [attr.tabindex]="routerLinkActiveInstance.isActive ? 0 : -1"
           [routerLink]="[navItem.route]">
          {{navItem.name}}
        </a>
      </div>
      <hr>
    </mat-nav-list>
    <mat-nav-list *ngIf="username">
      <mat-list-item class="hint-tiny">Selected Context:</mat-list-item>
      <mat-list-item>
        <mat-form-field class="last-n hint-tiny">
          <mat-select [ngModel]="getCurrentContextId() | async"
                      (ngModelChange)="setCurrentContextId($event)">
            <mat-option *ngFor="let ctxInfo of allContextInfos"
                        value="{{ctxInfo.contextId}}">{{ctxInfo.contextName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-list-item>
    </mat-nav-list>

    <mat-divider *ngIf="username"></mat-divider>
    <mat-nav-list>
      <button mat-raised-button color="primary" tabindex="-1" (click)="nav.close()">CLOSE</button>
    </mat-nav-list>
  </mat-sidenav>
  <div>
    <mat-toolbar color="primary">
      <button mat-icon-button (click)="nav.open('mouse')">
        <mat-icon>menu</mat-icon>
      </button>
      <div class="manager-toolbar">
        <h1 class="home-nav" (click)="homeNav()">{{title}}</h1>
        <div>
          <div class="text-right">
            <div>
              <span>UTC: {{now}} </span>
              <sup>{{config?.getConfig()?.appSettings?.conduitVersion}}</sup>
            </div>
          </div>
        </div>
      </div>
    </mat-toolbar>

    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
</mat-sidenav-container>

