import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {AppComponent} from "../../../app.component";
import {ConfigAssetsApiService, ConfigAssetsList} from "../config-assets-api.service";

@Component({
  selector: 'ae-config-assets-editor',
  templateUrl: './config-assets-editor.component.html',
  styleUrls: ['./config-assets-editor.component.scss']
})
export class ConfigAssetsEditorComponent implements OnInit, OnDestroy {
  configList: ConfigAssetsList = null;
  currentConfigId: string = null;
  currentConfig: any = null;
  newConfigId: string = null;
  editorOptions = new JsonEditorOptions();
  routeParams: Subscription;

  @ViewChild('editor', {static: false}) editor: JsonEditorComponent;

  constructor(
    private api: ConfigAssetsApiService,
    private app: AppComponent,
    private router: Router,
    private route: ActivatedRoute) {
  }

  private configRoot = "configs";

  ngOnInit(): void {
    this.routeParams = this.route.queryParams.subscribe((params) => {
      if (params.configId) {
        this.currentConfigId = params.configId;
        this.editConfig(this.currentConfigId);
      }
    });

    this.listConfigs();
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.mode = 'code';
    this.editorOptions.modes = ['code', 'tree'];
  }

  ngOnDestroy(): void {
    if (this.routeParams) {
      this.routeParams.unsubscribe();
    }
  }

  listConfigs(): void {
    this.api.listConfigs().subscribe((configList) => {
        this.configList = configList;
      },
      error => {
        this.app.showError("Fetch config assets error", error)
      });
  }

  editConfig(configId: string): void {
    this.api.getConfig(configId).subscribe((config) => {
        this.currentConfigId = configId;
        this.currentConfig = config;
      },
      error => {
        this.app.showError(`Error getting config '${configId}'`, error)
      });
  }

  setConfig(): void {
    this.api.setConfig(`${this.currentConfigId}`, this.editor.get()).subscribe(
      () => {
        this.closeEditor()
      },
      error => {
        this.app.showError(`Error updating config '${this.currentConfigId}'`, error)
      });
  }

  newConfig(): void {
    this.currentConfig = {};
    this.currentConfigId = `${this.configRoot}/${this.newConfigId}`;
  }

  deleteConfig(): void {
    console.log("delete config not supported");
  }

  refresh(): void {
    this.clearConfig();
    this.listConfigs();
  }

  clearConfig(): void {
    this.currentConfigId = null;
    this.currentConfig = null;
    this.newConfigId = null;
  }

  closeEditor(): void {
    this.refresh();
  }

}
