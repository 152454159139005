/* tslint:disable:no-console */
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {timer} from "rxjs";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {StationApiService, StationStatus} from "../station-api.service";

export interface ConditionData {
  conditions: string[];
  selected: string[];
}

export interface StateDate {
  states: string[];
  excluded: string[];
}

@Component({
  selector: 'ae-condition-dialog',
  templateUrl: 'condition-dialog.html',
})
export class ConditionsDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConditionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConditionData) {
  }

  onCancel(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'ae-states-dialog',
  templateUrl: 'states-dialog.html',
})
export class StatesDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<StatesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StateDate) {
  }

  onCancel(): void {
    this.dialogRef.close();
  }

}


@Component({
  selector: 'ae-st-status',
  templateUrl: './station-status.component.html',
  styleUrls: ['./station-status.component.scss']
})
export class StationStatusComponent implements OnInit, OnDestroy {
  private stationStream: Observable<StationStatus[]>;
  private timerStream;
  private refreshTimer;
  stationList: StationStatus[];
  historyList: StationStatus[];
  lastChecked = new Date();

  listCall = 'ready';
  historyStation;
  historyLimit = 100;
  historyEof = false;
  historyMeta = new Map();

  private defaultConditions = "WARNING,ERROR";
  conditions: string[] = ["OK", "WARNING", "ERROR"];
  filteredConditions: string[] = ["WARNING", "ERROR"];
  private excludedStates: string[] = [];
  allStates: string[] = [];

  constructor(private stationApi: StationApiService, public dialog: MatDialog) {
  }

  conditionsDialog(): void {
    const dialogRef = this.dialog.open(ConditionsDialogComponent, {
      width: '250px',
      data: {conditions: this.conditions, selected: this.filteredConditions}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.filteredConditions = result;
      }
      this.getHistory(this.historyStation);
    });
  }

  statesDialog(): void {
    const dialogRef = this.dialog.open(StatesDialogComponent, {
      width: '250px',
      data: {states: this.allStates, excluded: this.excludedStates}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.excludedStates = result;
      }
      this.getHistory(this.historyStation);
    });
  }


  ngOnInit(): void {
    this.refresh();
    this.setRefreshSubscription();
  }

  ngOnDestroy(): void {
    this.unsubscribeRefresh();
  }

  private setRefreshSubscription(): void {
    this.refreshTimer = null;
    this.unsubscribeRefresh();
    this.refreshTimer = timer(10000, 10000);
    this.timerStream = this.refreshTimer.subscribe(t => {
      this.refresh();
    });
  }

  private unsubscribeRefresh(): void {
    if (this.timerStream) {
      this.timerStream.unsubscribe();
    }
  }

  public getLastChecked(): number {
    return Math.round(((new Date()).getTime() - this.lastChecked.getTime()) / 1000);
  }

  public refresh(): void {
    this.lastChecked = new Date();
    this.listCall = 'working...';
    this.stationStream = this.stationApi.getDeviceStatus();

    const sub = this.stationStream.subscribe((stations) => {
      this.stationList = stations;
      this.listCall = 'ready';
      sub.unsubscribe();
    }, (error => {
      this.listCall = error.toString();
    }));
  }

  public getHistory(stationId: string): void {
    this.listCall = 'retrieving history for ' + stationId + ' ...';
    this.historyStation = null;
    this.historyEof = false;
    this.stationApi.getDeviceHistory(stationId, null, this.filteredConditions, this.excludedStates, this.historyLimit)
      .subscribe((history) => {
        this.historyList = history;
        this.buildStatesList();
        this.listCall = 'ready';
        this.historyStation = stationId;
        if (history.length < this.historyLimit) {
          this.historyEof = true;
        }
      }, (error => {
        this.historyStation = null;
        this.listCall = error.toString();
      }));
  }

  public moreHistory(): void {
    this.listCall = 'more history for ' + this.historyStation + ' ...';
    const lastCreated = this.historyList[this.historyList?.length - 1]?.created?.toString();
    this.stationApi.getDeviceHistory(this.historyStation, lastCreated, this.filteredConditions, this.excludedStates, this.historyLimit)
      .subscribe((history) => {
        this.historyList = this.historyList.concat(history);
        this.buildStatesList();
        this.listCall = 'ready';
        if (history.length === 0) {
          this.historyEof = true;
        }
      }, (error => {
        this.listCall = error.toString();
      }));
  }

  public buildStatesList(): void {
    if (this.historyList) {
      // only keep one of each state
      let states = this.historyList.map(ss => ss.state);
      states = states
        .filter((x, i) => states.indexOf(x) === i);
      // concat with the current list
      states = this.allStates.concat(states);
      // only keep one of each state for the combined list
      states = states.filter((x, i) => states.indexOf(x) === i);

      // update the current sate list
      this.allStates = states;
    }
  }

  public toggleMeta(id: string): void {
    if (this.historyMeta.has(id)) {
      this.historyMeta.delete(id);
    } else {
      this.historyMeta.set(id, null);
    }
  }

}
