import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription, timer} from 'rxjs';
import {ContextApiService} from "../context-api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AppComponent} from "../../../app.component";
import {AcdcApiService} from "../acdc-api.service";
import {AcdcRegistrationDetails, CreateAcdcConfigRequest} from "../acdc-models";

@Component({
  selector: 'ae-auth-acdc',
  templateUrl: './acdc-manager.component.html',
  styleUrls: ['./acdc-manager.component.scss']
})
export class AcdcManagerComponent implements OnInit, OnDestroy {

  public instanceDetails: AcdcRegistrationDetails[] = [];
  public selectedInstanceDetail: AcdcRegistrationDetails = null;
  public pendingInstanceName: string = null;
  public pendingInstanceUri: string = null;
  public pendingNewInstance: CreateAcdcConfigRequest = null;
  private lastUpdatedEpoch: number = +(new Date());
  public statusAgeSec = 0;
  private reloadContextInterval = 10;
  private reloadSubscription: Subscription;
  private lastUpdatedInterval = 1;
  private lastUpdatedSubscription: Subscription;

  constructor(
      private contextApi: ContextApiService,
      private acdcApi: AcdcApiService,
      private appComponent: AppComponent,
      private router: Router,
      private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.getAllInstanceDetails();
    this.startTimer();
  }

  ngOnDestroy(): void {
    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe();
    }
    if (this.lastUpdatedSubscription) {
      this.lastUpdatedSubscription.unsubscribe();
    }
  }

  startTimer(): void {
    if (!this.reloadSubscription) {
      const refreshTimer = timer(this.reloadContextInterval * 1000, this.reloadContextInterval * 1000);
      this.reloadSubscription = refreshTimer.subscribe(t => {
        this.getAllInstanceDetails();
      });
    }
    if (!this.lastUpdatedSubscription) {
      const lastUpdatedTimer = timer(this.lastUpdatedInterval * 1000, this.lastUpdatedInterval * 1000);
      this.lastUpdatedSubscription = lastUpdatedTimer.subscribe(t => {
        let nowEpoch: number = +new Date();
        this.statusAgeSec = Math.ceil((nowEpoch - this.lastUpdatedEpoch) / 1000);
      });
    }
  }

  getAllInstanceDetails(): void {
    this.acdcApi.getRegisteredInstanceDetails().subscribe((instanceDetails) => {
          // console.log("Reloading contexts");
          this.instanceDetails = instanceDetails.sort((a, b) => {
                const nameA = a.acdcInstanceConfig.instanceName
                const nameB = b.acdcInstanceConfig.instanceName
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0;
              }
          );
          this.lastUpdatedEpoch = +new Date();
        },
        error => {
          this.appComponent.showError(`Error getting all registered instance details`, error);
        });
  }

  selectInstance(instanceId: string): void {
    this.router.navigate(['acdc-manager'], {queryParams: {InstanceId: instanceId}}).then(() => {
      this.existingInstanceEditor(instanceId);
    });
  }


  existingInstanceEditor(instanceId: string): void {
    this.selectedInstanceDetail = this.instanceDetails.find((it) => {
      console.log(it);
      return it.acdcInstanceConfig.instanceId === instanceId;
    })
    this.pendingInstanceName = this.selectedInstanceDetail.acdcInstanceConfig.instanceName
    this.pendingInstanceUri = this.selectedInstanceDetail.acdcInstanceConfig.uri
  }

  registerNewInstanceEditor(): void {
    this.pendingNewInstance = new CreateAcdcConfigRequest("", "")
  }

  closeEditors(): void {
    this.getAllInstanceDetails();
    this.selectedInstanceDetail = null;
    this.pendingNewInstance = null;
    this.router.navigate(['acdc-manager']);
  }

  updateInstanceName(): void {
    this.acdcApi.updateInstanceName(
      this.selectedInstanceDetail.acdcInstanceConfig.instanceId,
      this.pendingInstanceName
    ).subscribe(
      _ => {
        this.selectedInstanceDetail.acdcInstanceConfig.instanceName = this.pendingInstanceName
      },
      error => {
        this.appComponent.showError(`Error updating instance name`, error)
      }
    )
  }

  updateInstanceUri(): void {
    this.acdcApi.updateInstanceUri(
      this.selectedInstanceDetail.acdcInstanceConfig.instanceId,
      this.pendingInstanceUri
    ).subscribe(
      _ => {
        this.selectedInstanceDetail.acdcInstanceConfig.uri = this.pendingInstanceUri
      },
      error => {
        this.appComponent.showError(`Error updating instance uri`, error)
      }
    )
  }

  createNewConfig(): void {
    this.acdcApi.createAcdcConfig(this.pendingNewInstance).subscribe(
      _ => {
        this.closeEditors()
      },
      error => {
        this.appComponent.showError(`Error registering new ACDC`, error)
      }
    )
  }

  public deleteConfig(): void {
    const instanceId = this.selectedInstanceDetail?.acdcInstanceConfig?.instanceId;
    if (instanceId) {
      if (confirm(`REMOVE ${instanceId} CONFIG ???`)) {
        this.acdcApi.removeAcdcConfig(instanceId).subscribe(
          _ => {
            this.closeEditors()
          },
          error => {
            this.appComponent.showError(`Error deregistering ACDC`, error)
          }
        )
      }
    } else {
      this.appComponent.showWarning("Only ACDC Instances *NOT* associated with a context may be deregistered")
    }
  }


  formatUtc(timestamp: string): string {
    if (timestamp) {
      return timestamp.split('.')[0] + 'Z';
    }
  }
}
