<div class="ae-container-wrapper">
  <h2>Config Asset Editing - {{configList?.project}} project</h2>
  <table class="table table-sm table-dark table-hover table-striped">
    <thead>
    <tr class="new-config">
      <th><input matInput type="text" placeholder="enter config location key starting below /configs" [(ngModel)]="newConfigId"></th>
      <th style="text-align: right">
        <button mat-stroked-button [disabled]="newConfigId?.length < 1" (click)="newConfig()">New</button>
      </th>
    </tr>
    <tr>
      <th>configuration assets</th>
      <th style="text-align: right"><button mat-raised-button color="accent" (click)="refresh()">Refresh</button></th>
    </tr>
    </thead>
    <tbody *ngIf="!currentConfig" >
    <tr *ngFor="let config of configList?.assets; index as i">
      <td colspan="2"><a class="link-active" (click)="editConfig(config)">{{config}}</a></td>
    </tr>
    </tbody>
  </table>

</div>

<div *ngIf="currentConfig" class="content">
  <div class="content">
    <mat-card class="mat-elevation-z4">
      <mat-card-header>
        <mat-card-title>Config</mat-card-title>
        <mat-card-subtitle> {{currentConfigId}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <json-editor #editor [options]="editorOptions" [data]="currentConfig"
                     style="height: 800px; width:800px;"></json-editor>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button (click)="clearConfig()">CLOSE</button>
        <button mat-button (click)="setConfig()">SAVE</button>
      </mat-card-actions>
    </mat-card>
  </div>

</div>
