import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {throwError} from "rxjs";
import {BuildInfo} from "./build-info";
import {BaseService} from "../base/base.service";

@Injectable()
export class UtilsApiService {

  constructor(private baseSvc: BaseService, private http: HttpClient) {
  }

  public getBuildInfo(): Observable<any> {
    const url = this.baseSvc.projectUrl(`/conduit/utils/build-info`);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json'
    })
    return this.http
      .get(url, {headers: headers})
      .pipe(
        map((auths: any) => auths as BuildInfo),
        catchError(error => {
          return throwError("Incorrect BuildInfo JSON")
        })
      );
  }

}
