export class UserInfo {
  userId: string;
  username: string;
  enabled: boolean;
  roles: Role[];
  dataSets: string[];
  realm: string;

  constructor(userId: string,
              username: string,
              enabled: boolean,
              roles: Role[],
              datasets: string[],
              realm: string) {
    this.userId = userId;
    this.username = username;
    this.enabled = enabled;
    this.roles = roles;
    this.dataSets = datasets;
    this.realm = realm;
  }
}

export class Role {
  id: string;
  name: string;
}
