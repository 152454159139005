import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {throwError} from "rxjs";
import {DataViewDefinition, DataViewResult} from "./data-view-models";
import {BaseService} from "../base/base.service";

@Injectable()
export class DataViewApiService {

  constructor(private baseSvc: BaseService, private http: HttpClient) {
  }

  public getDefinitions(): Observable<Array<DataViewDefinition>> {
    const url = this.baseSvc.projectUrl(`conduit/admin/data-view`);
    return this.http.get(
      url, {observe: 'body', headers: this.baseSvc.defaultHeaders()}
    ).pipe(
      map((response: object[]) => DataViewDefinition.fromObjectArray(response))
    );
  }

  public getDefaultDefinition(): Observable<DataViewDefinition> {
    const url = this.baseSvc.projectUrl(`conduit/admin/data-view/default`);
    return this.http.get(
      url, {observe: 'body', headers: this.baseSvc.defaultHeaders()}
    ).pipe(
      map((response: object) => DataViewDefinition.fromObject(response))
    );
  }


  public getDataView(contextId: string, definitionKey: string, fromTs: string, toTs: string): Observable<DataViewResult> {
    let url = this.baseSvc.projectUrl(`conduit/admin/data-view/${definitionKey}?params.contextId=${contextId}&page.n=1`);
    if (fromTs) {
      url = url + `&page.fromTs=${fromTs}`;
    }
    if (toTs) {
      url = url + `&page.toTs=${toTs}`;
    }
    return this.http
      .get(url, this.baseSvc.defaultOptions())
      .pipe(
        map((response: any) => response as DataViewResult,
          catchError(error => {
            return throwError("Incorrect DataView");
          })
        ));
  }


  public extractCsv(contextId: string, definitionKey: string, fromTs: string, toTs: string): Observable<ArrayBuffer> {
    let url = this.baseSvc.projectUrl(`conduit/admin/data-view/${definitionKey}/download?params.contextId=${contextId}`);
    if (fromTs) {
      url = url + `&page.fromTs=${fromTs}`;
    }
    if (toTs) {
      url = url + `&page.toTs=${toTs}`;
    }
    const options = this.baseSvc.defaultOptions({responseType: 'text'});
    return this.http.get(url, options);
  }

  public getRowDetails(contextId: string, definitionKey: string, queryMap: object): Observable<object> {
    console.log("queryMap");
    console.dir(queryMap);
    let url = this.baseSvc.projectUrl(`conduit/admin/data-view/${definitionKey}/details?contextId=${contextId}`);
    for (const key in queryMap) {
      if (queryMap.hasOwnProperty(key)) {
        url = url + `&${key}=${queryMap[key]}`;
      }
    }

    const options = this.baseSvc.defaultOptions();
    return this.http.get<object>(url, options);
  }

}
