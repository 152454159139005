<div *ngIf="!currentContext" class="ae-container-wrapper">
  <h2>Refset Management and Context Status</h2>
  <h4>Last updated {{this.statusAgeSec}}s ago</h4>
  <table class="table table-sm table-dark table-hover table-striped">
    <thead>
    <tr>
      <th>Context ID</th>
      <th>Context Name</th>
      <th>Context Desc</th>
      <th>ACDC Allocation</th>
      <th>ACDC Status Summary</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let rc of contexts; index as i">
      <td><a class="link-active" (click)="selectContext(rc.contextWrapper.contextId)">{{rc.contextWrapper.contextId}}</a></td>
      <td>{{rc.contextWrapper.contextInfo.contextName}}</td>
      <td>{{rc.contextWrapper.contextInfo.contextDesc}}</td>
      <td>{{rc.status || "UNKNOWN"}}</td>
      <td>{{rc.statusDetails || ""}}</td>
    </tr>
    </tbody>
  </table>

</div>

<div *ngIf="currentContext" class="content">
  <div class="content">\
    <mat-card class="mat-elevation-z4">
      <mat-card-header>
        <mat-card-title>Context Registration</mat-card-title>
        <mat-card-subtitle> ID: {{currentContext?.contextWrapper?.contextId}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        NAME: {{currentContext?.contextWrapper?.contextInfo?.contextName}}
      </mat-card-content>
      <mat-card-content>
        REFSET DB COUNT: {{currentDbCount}}
      </mat-card-content>
      <mat-card-content>
        REFSET MEMORY COUNT: {{currentMemoryCount}}
      </mat-card-content>
      <mat-card-content>
        STATUS: {{currentStatus}}
      </mat-card-content>
      <mat-card-content>
        STATUS DETAILS: {{currentStatusDetails}}
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button (click)="closeManager()">CLOSE</button>
        <button mat-button (click)="activateContext()">ACTIVATE</button>
        <button mat-button (click)="deactivateContext()">DEACTIVATE</button>
        <button mat-button [disabled]="!clearContextEnabled" (click)="clearContext()">CLEAR CONTEXT</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
