export class AlitheonError {
  userMessage: string;
  traceId: string;
  callName: string;
  status?: number;
  message?: string;
  errorCode?: string;
  misc?: any;

  constructor(userMessage: string, traceId: string, callName: string, status?:number, message?: string, errorCode?: string, misc?: any) {
    this.traceId = traceId;
    this.callName = callName;
    this.status = status;
    this.message = message;
    this.errorCode = errorCode;
    this.userMessage = userMessage;
    this.misc = misc;
  }

  public static fromError(httpError: any): AlitheonError {
    let ae = new AlitheonError("n/a", "n/a", "n/a", null, "n/a", "n/a", null);
    try {
      const error = httpError.error;
      ae.userMessage = error.userMessage ? error.userMessage : ae.userMessage;
      ae.traceId = error.traceId ? error.traceId : ae.traceId;
      ae.callName = error.callName ? error.callName : ae.callName;
      ae.status = (httpError.status || !isNaN(parseInt(httpError.status))) ? httpError.status : null;
      ae.message = error.message ? error.message : ae.message;
      ae.errorCode = error.errorCode ? error.errorCode : ae.errorCode;
      ae.misc = error.misc ? error.misc : ae.misc;
    } catch (e) {
      console.log("error handle exception:", e, ae);
    }
    return ae;
  }
}
