<div class="ae-container-wrapper">
  <h2>Context Editing</h2>
</div>

<div class="content">
  <div class="content">
    <mat-card class="mat-elevation-z4">
      <mat-card-header>
        <mat-card-title>Station Monitoring Config</mat-card-title>
        <mat-card-subtitle>Configuration for Station</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <json-editor #editor [options]="editorOptions" [data]="configJson" style="height: 800px; width:800px;"></json-editor>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button (click)="cancel()">CANCEL</button>
        <button mat-button (click)="updateConfig()">SAVE</button>
      </mat-card-actions>
    </mat-card>
  </div>

</div>
